// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, forkJoin, throwError} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { OperatingSystems, ValuesService } from '../../../../../common/values/values.service';
import { DevicesService } from '../devices.service';
import { UsefulService } from '../../../global/useful/useful.service';
import { SeccenterService } from '../../../requests/connect-seccenter-service/connect-seccenter.service';
import { IssuesMgmtService } from '../../../requests/connect-issues-mgmt/connect-issues-mgmt.service';
import { ConnectMgmtService } from '../../../requests/connect-mgmt-service/connect-mgmt.service';
import { ConnectUserInfoService } from '../../../requests/connect-user-info-service/connect-user-info-service';
import { TasksMgmtService } from '../../../requests/connect-tasks-mgmt-service/connect-tasks-mgmt.service';
import { AntiTheftMgmtService } from '../../../requests/connect-antitheft-mgmt-service/connect-antitheft-mgmt.service';
import { UtilsCommonService } from '../../../../../common/utils/utils-common.service';
import { DevicesValuesService } from '../../../../../common/values/devices.values';


@Injectable({
    providedIn: 'root'
})

export class DevicesDetailsService {

    constructor(
        private valuesService: ValuesService,
        private usefulService: UsefulService,
        readonly activatedRoute: ActivatedRoute,
        readonly devicesService: DevicesService,
        private seccenterService: SeccenterService,
        readonly issuesMgmtService: IssuesMgmtService,
        readonly connectMgmtService: ConnectMgmtService,
        readonly connectUserInfoService: ConnectUserInfoService,
        readonly tasksMgmtService: TasksMgmtService,
        readonly antiTheftMgmtService: AntiTheftMgmtService,
        readonly utilsCommonService: UtilsCommonService,
        readonly devicesValuesService: DevicesValuesService
    ) { }

    countThreats(deviceCrc): Observable<any> {
        let device = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        let checkFlag = this.usefulService.getNested(device, undefined, "processed", "threats", "countThreats", "markToUpdate");
        let onList = this.usefulService.getNested(device, null, "processed", "threats", "countThreats", "onlist");
        let days = 6;

        if (checkFlag !== undefined && !checkFlag) {
            return of(device);
        }

        if (!device['processed']) {
            device['processed'] = {};
        }

        if (!device['processed']['threats']) {
            device['processed']['threats'] = {};
        }

        if (!device['processed']['threats']['countThreats']) {
            device['processed']['threats']['countThreats'] = {};
        }

        if (onList === null) {
            device.processed.threats.countThreats.onlist = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
            onList = this.usefulService.getNested(device, null, "processed", "threats", "countThreats", "onlist");
        }

        if (onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return onList.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.countThreats(days, device.device_id)
            .pipe(
                map(
                     resp => {
                        if (resp) {
                            device['processed']['threats']['countThreats']['all'] = resp;
                            device['processed']['threats']['countThreats'].markToUpdate = false;
                        } else {
                            device['processed']['threats']['countThreats'].markToUpdate = true;
                            onList.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                        onList.next(this.valuesService.processServiceState.DONE);
                     }
                ),
                catchError((err) => {
                    device['processed']['threats']['countThreats'].markToUpdate = true;
                    onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listMalware(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const malware = this.usefulService.getNested(device, null, 'processed', 'malware');
        if (!malware) {
            if (!device.processed) {
                device.processed = {};
            }

            device.processed.malware = {
                [this.devicesValuesService.tasksName.malware.system]: {},
                onlistScan: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateScan: true,

                [this.devicesValuesService.tasksName.malware.quick]: {},
                onlistQuickScan: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateQuickScan: true
            };
        }

        return forkJoin([
            this.listMalwareScan(deviceId)
            .pipe(
                catchError((err) => {
                    device.processed.malware.onlistScan.next(this.valuesService.processServiceState.DONE);
                    return of({'error': err, 'isError': true});
                })
            ),
            this.listMalwareQuickScan(deviceId)
            .pipe(
                catchError((err) => {
                    device.processed.malware.onlistQuickScan.next(this.valuesService.processServiceState.DONE);
                    return of({'error': err, 'isError': true});
                })
            ),
        ]);
    }

    listMalwareScan(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const malware = this.usefulService.getNested(device, null, 'processed', 'malware');
        if (malware.markToUpdateScan === false) {
            return of(device);
        }

        const onListScan = this.usefulService.getNested(device, null, 'processed', 'malware', 'onlistScan');
        if (onListScan.value === this.valuesService.processServiceState.INPROGRESS) {
            return onListScan.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onListScan.next(this.valuesService.processServiceState.INPROGRESS);
            return this.tasksMgmtService.getSummary(deviceId, this.devicesValuesService.tasksName.malware.system)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        malware[this.devicesValuesService.tasksName.malware.system] = resp;
                        onListScan.next(this.valuesService.processServiceState.DONE);
                        malware.markToUpdateScan = false;
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    onListScan.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    updateMalwareScan(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const malware = this.usefulService.getNested(device, null, 'processed', 'malware');
        if (malware) {
            malware.markToUpdateScan = true;
        }
    }

    listMalwareQuickScan(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const malware = this.usefulService.getNested(device, null, 'processed', 'malware');
        if (malware.markToUpdateQuickScan === false
            || device.device_os === OperatingSystems.ANDROID
            || device.device_os === OperatingSystems.IOS) {
            return of(device);
        }

        const onListQuickScan = this.usefulService.getNested(device, null, 'processed', 'malware', 'onlistQuickScan');
        if (onListQuickScan.value === this.valuesService.processServiceState.INPROGRESS) {
            return onListQuickScan.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onListQuickScan.next(this.valuesService.processServiceState.INPROGRESS);
            return this.tasksMgmtService.getSummary(deviceId, this.devicesValuesService.tasksName.malware.quick)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        malware[this.devicesValuesService.tasksName.malware.quick] = resp;
                        onListQuickScan.next(this.valuesService.processServiceState.DONE);
                        malware.markToUpdateQuickScan = false;
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    onListQuickScan.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    updateMalwareQuickScan(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const malware = this.usefulService.getNested(device, null, 'processed', 'malware');
        if (malware) {
            malware.markToUpdateQuickScan = true;
        }
    }

    listIssuesOnDevice(deviceCrc, app): Observable<any> {
        const device = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const checkFlag = this.usefulService.getNested(device, undefined, 'processed', "issues", "markToUpdate");
        let onList = this.usefulService.getNested(device, null, 'processed', 'issues', 'onlist');

        if (checkFlag !== undefined && !checkFlag) {
            return of(device);
        }

        if (!device.processed.issues) {
            device.processed.issues = {};
        }

        if (onList === null) {
            device.processed.issues.onlist = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
            onList = this.usefulService.getNested(device, null, 'processed', 'issues', 'onlist');
        }

        // daca issues s-au rezolvat, nu e nevoie de request, e nevoie doar de curatatarea listei
        const issues = device?.protection_status?.issues ? device.protection_status.issues : 0;
        if (issues === 0) {
            device.processed.issues.protectionApp = app;
            device.processed.issues.list = [];
            return of(device);
        }

        if (onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return onList.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.issuesMgmtService.getIssues(device.device_id)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        if (resp.status === 1) {
                            resp.issues = [];
                        }

                        device.processed.issues.list = [...resp.issues];
                        device.processed.issues.markToUpdate = false;
                        device.processed.issues.protectionApp = app;
                        onList.next(this.valuesService.processServiceState.DONE);
                    } else {
                        device.processed.issues.markToUpdate = true;
                        device.processed.issues.protectionApp = app;
                        onList.next(this.valuesService.processServiceState.DONE);
                        return of('malformed_request');
                    }
                }),
                catchError((err) => {
                    device.processed.issues.markToUpdate = true;
                    device.processed.issues.protectionApp = app;
                    onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listPortForward(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const portForward = this.usefulService.getNested(device, null, 'processed', 'portForward');
        if (!portForward) {
            if (!device.processed) {
                device.processed = {};
            }

            device.processed.portForward = {
                port_fwd: [],
                onlist: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING)
            };
        }

        const onListPortForward = this.usefulService.getNested(device, null, 'processed', 'portForward', 'onlist');
        if (onListPortForward.value === this.valuesService.processServiceState.INPROGRESS) {
            return onListPortForward.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onListPortForward.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listPortForward(device)
            .pipe(
                map((resp: any) => {
                    if (resp.port_fwd) {
                        device.processed.portForward.port_fwd = resp.port_fwd;
                        device.processed.portForward.onlist.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    device.processed.portForward.onlist.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Lists available antitheft commands for given device id
     * @public
     * @memberof DevicesDetailsService
     * @param {string} deviceCrc The device id
     */
    public listCommandsAntitheft(deviceCrc: string): void {
        const device = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const antitheft = device?.processed?.antitheft;

        if (!antitheft) {
            if (!device.processed) {
                device.processed = {};
            }

            device.processed.antitheft = {
                onlist: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateList: true,

                onlistLocation: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateLocation: true,

                onlistStatusLock: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateStatusLock: true,

                onlistSnapPhoto: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateSnapPhoto: true
            };
        }
    }

    listAntitheftOnDevice(deviceCrc): Observable<any> {
        const device = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const appId  =  this.valuesService.bundleBMS;
        const antitheft = this.usefulService.getNested(device, undefined, 'processed', "antitheft");

        if(antitheft.markToUpdateList !== undefined && !antitheft.markToUpdateList) {
            return of(device);
        }

        const onListAntitheft = this.usefulService.getNested(device, null, 'processed', 'antitheft', 'onlist');
        if (onListAntitheft.value === this.valuesService.processServiceState.INPROGRESS) {
            return onListAntitheft.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onListAntitheft.next(this.valuesService.processServiceState.INPROGRESS);
            return this.antiTheftMgmtService.checkCommandSettings(device.device_id, appId)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        device.processed.antitheft.list = {};
                        if (resp.remote_commands) {
                            device.processed.antitheft.list = resp.remote_commands;
                            device.processed.antitheft.status = '';
                        } else {
                            device.processed.antitheft.status = resp;
                        }

                        device.processed.antitheft.markToUpdateList = false;
                        onListAntitheft.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        device.processed.antitheft.markToUpdateList = true;
                        onListAntitheft.next(this.valuesService.processServiceState.DONE);
                        return of('malformed_request');
                    }
                }),
                catchError((err) => {
                    device.processed.antitheft.markToUpdateList = true;
                    onListAntitheft.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }


    listIp(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const ip = this.usefulService.getNested(device, null, 'processed', 'ip');
        if (!ip) {
            if (!device.processed) {
                device.processed = {};
            }

            device.processed.ip = {
                ip_alloc: [],
                onlist: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING)
            };
        }

        const onListPortForward = this.usefulService.getNested(device, null, 'processed', 'ip', 'onlist');
        if (onListPortForward.value === this.valuesService.processServiceState.INPROGRESS) {
            return onListPortForward.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onListPortForward.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listIp(device)
            .pipe(
                map((resp: any) => {
                    if (resp.ip_alloc) {
                        device.processed.ip.ip_alloc = resp.ip_alloc;
                        device.processed.ip.onlist.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    device.processed.ip.onlist.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    setIp(deviceId, newIp) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        let ip = this.usefulService.getNested(device, null, 'processed', 'ip');

        return this.connectMgmtService.setIp(device, newIp)
        .pipe(
            map(
                (resp) => {
                    try {
                        ip.ip_alloc = [{
                                ip_suffix: newIp,
                                device_id: deviceId,
                                mac: device.macs[0],
                                // setarile astea 2 sunt hardcodate, ele vin cu adevarat lor valoare doar la list e ip-uri
                                // au fost lasate asa pt ca nu sunt folosite nicaieri, dar daca ar fi,
                                // ar trebui sa fie regandit flowul si poate se elimine hardcodarea
                                setting_id: "6025533595345244dd4a1a65",
                                status: this.devicesValuesService.taskStates.PENDING,

                        }];
                    } catch {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    return throwError(err);
                }
            )
        );
    }

    removeIp(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        let ip = this.usefulService.getNested(device, null, 'processed', 'ip');

        return this.connectMgmtService.removeIp(device)
        .pipe(
            map(
                (resp) => {
                    try {
                        ip.ip_alloc = [];
                    } catch {
                        return throwError(resp);
                    }
                }
            ),
            catchError(
                (err) => {
                    return throwError(err);
                }
            )
        );
    }

    /**
     * Gets device location
     * @public
     * @memberof DevicesDetailsService
     * @param {string} deviceCrc The device id
     * @returns {Observable} Returns the location if success, otherwise the error received
     */
    public listLocationOnDevice(deviceCrc: string): Observable<any> {
        const device = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        if (device?.processed?.antitheft?.markToUpdateLocation !== undefined && !device?.processed?.antitheft?.markToUpdateLocation) {
            return of(device);
        }

        if (!device?.processed?.antitheft) {
            this.listCommandsAntitheft(deviceCrc);
        }

        const antitheft = device?.processed?.antitheft;
        if (!antitheft?.location) {
            antitheft.location = {};
        }

        const onListLocation = antitheft?.onlistLocation;
        if (onListLocation.value === this.valuesService.processServiceState.INPROGRESS) {
            return onListLocation.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            onListLocation.next(this.valuesService.processServiceState.INPROGRESS);

            const appId = this.utilsCommonService.getDeviceOS(device);
            return this.antiTheftMgmtService.getLocation(device.device_id, appId)
            .pipe(
                map(resp => {
                    if (!resp) {
                        antitheft.markToUpdateLocation = true;
                        onListLocation.next(this.valuesService.processServiceState.DONE);
                        return of('malformed_request');
                    }

                    if (resp?.status) {
                        antitheft.location.info = {
                            lat: this.devicesValuesService.notCoords,
                            long: this.devicesValuesService.notCoords
                        };
                    } else {
                        antitheft.location.info = resp;
                    }

                    antitheft.markToUpdateLocation = false;
                    onListLocation.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                }),
                catchError(err => {
                    antitheft.markToUpdateLocation = true;
                    onListLocation.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listSnapPhotoOnDevice(deviceCrc): Observable<any> {
        const device    = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const antitheft = this.usefulService.getNested(device, undefined, 'processed', "antitheft");

        if(antitheft.markToUpdateSnapPhoto !== undefined && !antitheft.markToUpdateSnapPhoto) {
            return of(device);
        }

        if (!device.processed.antitheft.snapPhoto) {
            device.processed.antitheft.snapPhoto = {};
        }

        const onlistSnapPhoto= this.usefulService.getNested(device, null, 'processed', 'antitheft', 'onlistSnapPhoto');
        if (onlistSnapPhoto.value === this.valuesService.processServiceState.INPROGRESS) {
            return onlistSnapPhoto.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onlistSnapPhoto.next(this.valuesService.processServiceState.INPROGRESS);
            return this.antiTheftMgmtService.getSnap(device.device_id)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        device.processed.antitheft.snapPhoto = resp;
                        device.processed.antitheft.markToUpdateSnapPhoto = false;
                        onlistSnapPhoto.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        device.processed.antitheft.markToUpdateSnapPhoto = true;
                        onlistSnapPhoto.next(this.valuesService.processServiceState.DONE);
                        return of('malformed_request');
                    }
                }),
                catchError((err) => {
                    device.processed.antitheft.markToUpdateSnapPhoto = true;
                    onlistSnapPhoto.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listStatusLockOnDevice(deviceCrc): Observable<any> {
        const device    = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const antitheft = this.usefulService.getNested(device, undefined, 'processed', "antitheft");
        const appId     = this.valuesService.bundleBMS;

        if(antitheft.markToUpdateStatusLock !== undefined && !antitheft.markToUpdateStatusLock) {
            return of(device);
        }

        if (!device.processed.antitheft.lock) {
            device.processed.antitheft.lock = {};
        }

        const onlistStatusLock = this.usefulService.getNested(device, null, 'processed', 'antitheft', 'onlistStatusLock');
        if (onlistStatusLock.value === this.valuesService.processServiceState.INPROGRESS) {
            return onlistStatusLock.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onlistStatusLock.next(this.valuesService.processServiceState.INPROGRESS);
            return this.antiTheftMgmtService.getLockStatus(device.device_id, appId)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        device.processed.antitheft.lock.info = resp;
                        device.processed.antitheft.markToUpdateStatusLock = false;
                        onlistStatusLock.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        device.processed.antitheft.markToUpdateStatusLock = true;
                        onlistStatusLock.next(this.valuesService.processServiceState.DONE);
                        return of('malformed_request');
                    }
                }),
                catchError((err) => {
                    device.processed.antitheft.markToUpdateStatusLock = true;
                    onlistStatusLock.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    assignOwner(profile, device) {
        const params = {
            'profile_id': profile.profile_id,
            'device_id': device.device_id
        };

        if (profile.sid) {
            params['device_account_sid'] = profile.sid;
        }

        return this.connectUserInfoService.profileSetActive(params)
        .pipe(
            map(resp => {
                if (resp.status === 0) {
                    device.profile_id = profile.profile_id;
                } else {
                    throw of('Request error');
                }
            }),
            catchError( err => {
                throw err;
            })
        );
    }

    unassignOwner(profile, device) {
        const params = {
            'profile_id': profile.profile_id,
            'device_id': device.device_id
        };

        if (profile.sid) {
            params['device_account_sid'] = profile.sid;
        }

        return this.connectUserInfoService.profile_set_inactive(params)
        .pipe(
            map( resp => {
                if (resp.status === 0) {
                    device.profile_id = profile.profile_id;
                } else {
                    throw of('Request error');
                }
            }),
            catchError( err => {
                throw err;
            })
        );
    }

    updateIssues(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const onList = this.usefulService.getNested(device, null, 'processed', 'issues', 'onlist');
        const issues = this.usefulService.getNested(device, {}, 'processed', 'issues');

        if (onList && onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            issues.markToUpdate = true;
        }
    }

    updateThreats(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const onList = this.usefulService.getNested(device, null, "processed", "threats", "countThreats", "onlist");
        const threats = this.usefulService.getNested(device, {}, "processed", "threats", "countThreats");

        if (onList && onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            threats.markToUpdate = true;
        }
    }

    listVulnerability(deviceId, scanType) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        let vulnerability = this.usefulService.getNested(device, null, 'processed', 'vulnerability');
        if (!vulnerability) {
            if (!device.processed) {
                device.processed = {};
            }

            device.processed.vulnerability = {
                onlist: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdate: true
            };
        }

        vulnerability = this.usefulService.getNested(device, null, 'processed', 'vulnerability');

        if (vulnerability.markToUpdate === false) {
            return of(device);
        }

        const onList = this.usefulService.getNested(device, null, 'processed', 'vulnerability', 'onlist');
        if (onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return onList.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.tasksMgmtService.getSummary(device.device_id, scanType)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        vulnerability[scanType] = resp;
                        onList.next(this.valuesService.processServiceState.DONE);
                        vulnerability.markToUpdate = false;
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    updateVulnerability(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const vulnerability = this.usefulService.getNested(device, {}, 'processed', 'vulnerability');
        vulnerability.markToUpdate = true;
    }

    sendCommandMalware(deviceId, scanType) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const scan = this.usefulService.getNested(device, {}, "processed", "malware", scanType);
        return this.tasksMgmtService.sendTask(deviceId, { task: scanType }, {})
            .pipe(
                map(
                    (resp) =>  {
                        if (resp && resp.task_state) {
                            scan.task_state = resp.task_state;
                        } else {
                            return throwError("Error");
                        }
                    }
                ),
                catchError(
                    (err) => {
                        throw err;
                    }
                )
            );
    }

    sendCommandVulnerability(deviceId, scanType) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const scan = this.usefulService.getNested(device, {}, "processed", "vulnerability", scanType);
        let params = {};
        const info = { task: scanType };

        if (scanType === this.devicesValuesService.tasksName.vulnerability.scan) {
            params = {
                "windowsUpdates": 1,
                "appUpdates": 1,
                "weakPasswords": 1,
                "wifi": 1
            }
        }

        if (scanType === this.devicesValuesService.tasksName.vulnerability.assessment) {
            info["app_id"] = this.valuesService.appBOX2;
        }

        return this.tasksMgmtService.sendTask(deviceId, info, params)
            .pipe(
                map(
                    (resp) =>  {
                        if (resp && resp.task_state) {
                            scan.task_state = resp.task_state;
                        } else {
                            return throwError("Error");
                        }
                    }
                ),
                catchError(
                    (err) => {
                        throw err;
                    }
                )
            );
    }

    updateLocationOnDevice(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const antitheft = this.usefulService.getNested(device, null, 'processed', 'antitheft');
        if (antitheft) {
            antitheft.markToUpdateLocation = true;
        }
    }

    /**
     * Mark to update antitheft requests for given device id
     * @public
     * @memberof DevicesDetailsService
     * @param {string} deviceId The device id to be updated
     */
    public updateAntitheftOnDevice(deviceId: string): void {
        if (!deviceId) {
            return;
        }

        const device = this.devicesService.retrieveDeviceById(deviceId);
        const antitheft = device?.processed?.antitheft;

        if (antitheft) {
            antitheft.markToUpdateList = true;
        }
    }

    updateSnapPhotonOnDevice(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const antitheft = this.usefulService.getNested(device, null, 'processed', 'antitheft');
        if (antitheft) {
            antitheft.markToUpdateSnapPhoto = true;
        }
    }

    getAccountStateOnDevice(deviceCrc) {
        const device = this.devicesService.retrieveDeviceByCrc(deviceCrc);

        if (device.device_accounts && device.device_accounts.length) {
            let hasMsAccountWithPass = false;
            let hasAdminWithPassword = false;

            for(const account of device.device_accounts) {
                if (account.privileges === this.devicesValuesService.deviceAccounts.ADMIN_PRIVILEGES_2) {
                    if (account.user_pass === this.devicesValuesService.deviceAccounts.NO_PASSWORD
                            && account.is_enabled !== this.devicesValuesService.deviceAccounts.ADMIN_NOT_ENABLED) {
                        return this.devicesValuesService.lockWindowsCases.WITHOUT_PASS; // 'account admin without pass';
                    }

                    if (account.user_pass === this.devicesValuesService.deviceAccounts.HAS_PASSWORD) {
                        hasAdminWithPassword = true;
                    }

                }

                if (account.internet_provider !== null && account.internet_provider === this.devicesValuesService.deviceAccounts.MICROSOFT_ACCOUNT) {
                    hasMsAccountWithPass = true;
                }

            }

            if (hasMsAccountWithPass) {
                return this.devicesValuesService.lockWindowsCases.PASS_MS; // 'account admin pass with ms'
            }

            if (hasAdminWithPassword) {
                return this.devicesValuesService.lockWindowsCases.WITH_PASS; // 'account admin with pass'
            }
        }

        return this.devicesValuesService.lockWindowsCases.WITHOUT_ACCOUNTS;
    }

    listOneclickOptimizer(deviceCrc): Observable<any> {
        const device    = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const optimizer = this.usefulService.getNested(device, undefined, 'processed', 'optimizer');
        if (!optimizer) {
            if (!device.processed) {
                device.processed = {};
            }
            device.processed.optimizer = {
                onlistOptimize: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
                markToUpdateOptimize: true
            };
            if ((device.processed.clMajorVersionInstalled).toString() < this.devicesValuesService.clVersion) {
                device.processed.optimizer.onlistStartup       = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
                device.processed.optimizer.markToUpdateStartup = true;
            }
        }
        // daca pica un request din cele 2, al doilea nu mai ajunge la done si ramane in progress
        // trebuie prinsa eroare pe fiecare request
        if ((device.processed.clMajorVersionInstalled).toString() < this.devicesValuesService.clVersion) {
            return forkJoin(
                this.listOptimizeOnDevice(deviceCrc)
                .pipe(
                    catchError((err) => {
                        device.processed.malware.onlistScan.next(this.valuesService.processServiceState.DONE);
                        return of({'error': err, 'isError': true});
                    })
                ),
                this.listStartupOnDevice(deviceCrc)
                .pipe(
                    catchError((err) => {
                        device.processed.malware.onlistScan.next(this.valuesService.processServiceState.DONE);
                        return of({'error': err, 'isError': true});
                    })
                )
            );
        } else {
            return this.listOptimizeOnDevice(deviceCrc);
        }
    }

    checkLastUpdateReport(deviceCrc, resp) {
        const reportRead  = localStorage.getItem(deviceCrc + this.devicesValuesService.flagReportRead);
        const lastSummary = localStorage.getItem(deviceCrc + this.devicesValuesService.flagOptimizerSummary);
        let flag          = 'true';
        if (lastSummary !== null) {
            if (reportRead && reportRead === 'false') {
                flag = 'false';
            } else {
                if (lastSummary === "undefined" && resp.last_summary_timestamp) {
                    flag = 'false'; // necitit
                } else if (Number.parseInt(lastSummary) < resp.last_summary_timestamp) {
                    flag = 'false'; // necitit
                } else {
                    flag = 'true';
                }
            }
        }
        localStorage.setItem(deviceCrc + this.devicesValuesService.flagReportRead, flag);
        localStorage.setItem(deviceCrc + this.devicesValuesService.flagOptimizerSummary, resp.last_summary_timestamp);
    }

    listOptimizeOnDevice(deviceCrc) {
        const device    = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const optimizer = this.usefulService.getNested(device, undefined, 'processed', "optimizer");
        if(optimizer.markToUpdateOptimize !== undefined && !optimizer.markToUpdateOptimize) {
            return of(device);
        }
        const onlistOptimize = this.usefulService.getNested(device, null, 'processed', 'optimizer', 'onlistOptimize');
        if (onlistOptimize.value === this.valuesService.processServiceState.INPROGRESS) {
            return onlistOptimize.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onlistOptimize.next(this.valuesService.processServiceState.INPROGRESS);
            return this.tasksMgmtService.getSummary(device.device_id, this.devicesValuesService.tasksName.optimizer.optimize)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        this.checkLastUpdateReport(deviceCrc, resp);
                        device.processed.optimizer.optimize = resp;
                        device.processed.optimizer.markToUpdateOptimize = false;
                        onlistOptimize.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    device.processed.optimizer.markToUpdateOptimize = true;
                    onlistOptimize.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    listStartupOnDevice(deviceCrc) {
        const device    = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const optimizer = this.usefulService.getNested(device, undefined, 'processed', "optimizer");
        if(optimizer.markToUpdateStartup !== undefined && !optimizer.markToUpdateStartup) {
            return of(device);
        }
        const onlistStartup = this.usefulService.getNested(device, null, 'processed', 'optimizer', 'onlistStartup');
        if (onlistStartup.value === this.valuesService.processServiceState.INPROGRESS) {
            return onlistStartup.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            onlistStartup.next(this.valuesService.processServiceState.INPROGRESS);
            return this.tasksMgmtService.getSummary(device.device_id, this.devicesValuesService.tasksName.optimizer.startup)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        device.processed.optimizer.startup = resp;
                        device.processed.optimizer.markToUpdateStartup = false;
                        onlistStartup.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    } else {
                        throw of("Malformed request");
                    }
                }),
                catchError((err) => {
                    device.processed.optimizer.markToUpdateStartup = true;
                    onlistStartup.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    sendCommandOptimizer(deviceCrc, commandType, params, option) {
        const device    = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const optimizer = this.usefulService.getNested(device, {}, "processed", "optimizer", option);
        return this.tasksMgmtService.sendTask(device.device_id, { task: commandType }, params)
            .pipe(
                map(
                    (resp) =>  {
                        if (resp && resp.task_state) {
                            optimizer.task_state = resp.task_state;
                        } else {
                            throw resp;
                        }
                    }
                ),
                catchError(
                    (err) => {
                        throw err;
                    }
                )
            );
    }

    updateOptimizeOnDevice(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const optimizer = this.usefulService.getNested(device, null, 'processed', 'optimizer');
        if (optimizer) {
            optimizer.markToUpdateOptimize = true;
        }
    }

    updateStartupOnDevice(deviceId) {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const optimizer = this.usefulService.getNested(device, null, 'processed', 'optimizer');
        if (optimizer) {
            optimizer.markToUpdateStartup = true;
        }
    }

    renameDevice(deviceId, newDeviceName): Observable<any> {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        return this.connectMgmtService.setDeviceFriendlyName(newDeviceName, deviceId)
        .pipe(
            map((resp) => {
                if (resp) {
                    device.display_name = newDeviceName;
                    return of(true);
                } else {
                    return throwError("Error");
                }
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    updateDeviceLocationInfo(deviceCrc, locationInfo) {
        const device = this.devicesService.retrieveDeviceByCrc(deviceCrc);
        const { location: address, lat, lng: long, timestamp } = locationInfo;
        if (device && device?.processed?.antitheft?.location?.info
            && typeof address === 'string'
            && typeof lat === 'number'
            &&  typeof long === 'number'
            &&  typeof timestamp === 'number') {
            device.processed.antitheft.location.info = {
                ...device.processed.antitheft.location.info,
                address,
                lat,
                long,
                timestamp: timestamp / 1000
            };
        }
    }
}
// External
import { retry, map, catchError, timeout, skipWhile } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tv4 } from 'tv4';

// Internal
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { FailedToFetch, RequestsDataBaseName, ServiceWorkerIsNoLongerResponsive, UserShouldNotTryToWriteToDataBase, ValuesService } from '../../../values/values.service';
import { schemas } from '../../../models/schemas';
import { ConfigService, LogoutType } from '../../../config/config.service';
import { UsefulService } from '../useful/useful.service';
import { MessageService } from '../../core/message.service';
import { LoginLinksService } from '../loginLinks/loginLinks.service';
import { ConnectWebmailRegistrationServiceErrorSubCodes } from '../../requests/connect-webmailregistration/connect-webmailregistration.service';
import { AuthService } from '../auth/auth-service.service';
import { HandleUnrecoverableStateService } from '../handle-unrecoverable-state/handle-unrecoverable-state.service';
import { PrivacyErrorCodes, PrivacyEvents } from '../../../values/privacy.values.service';

export const POST = 'POST';

export enum Errors {
    ERROR = 'error',
    INVALID_PARAMS = 'Invalid params',
    SERVICE_ERROR = 'service_error',
    WRONG_CREDENTIALS = 'Wrong credentials'
}

export enum Methods {
    REGISTER_INBOX = 'register_inbox'
}

export enum Services {
    CONNECT_WEBMAIL_PROTECTION = 'connect/webmail_protection'
}

/**
 * Deletes the service worker cache and the local storage variables at logout/login/unregister.
 */
export function deleteServiceWorkerCacheAndLocalStorageVariables(): void {
    localStorage.removeItem(UserShouldNotTryToWriteToDataBase);
    localStorage.removeItem(FailedToFetch);
    localStorage.removeItem(ServiceWorkerIsNoLongerResponsive);
    const DBDeleteRequest = window?.indexedDB?.deleteDatabase(RequestsDataBaseName);

    DBDeleteRequest.onerror = function(_event) {
        console.log('Error deleting database.');
    };

    DBDeleteRequest.onsuccess = function(_event) {
        console.log('Database deleted successfully');
    };
}

@Injectable({
    providedIn: 'root'
})

export class RequestsService {
    errors = {
        request_status_error: 100,
        wrong_host: 101,
        wrong_service: 102,
        wrong_method: 103,
        wrong_params: 104,
        service_error: 105
    };
    markToUpdateLogout = true;
    private readonly onlistLogout$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly privacyErrorCodes = [
        PrivacyErrorCodes.DATABASE_ERROR,
        PrivacyErrorCodes.IDENTITY_EXISTS,
        PrivacyErrorCodes.NO_IDENTITY,
        PrivacyErrorCodes.INFO_NOT_FOUND,
        PrivacyErrorCodes.INFO_EXISTS,
        PrivacyErrorCodes.INFO_INVALID,
        PrivacyErrorCodes.INFO_VALIDATED,
        PrivacyErrorCodes.SEND_CODE_ERR,
        PrivacyErrorCodes.VALIDATION_CODE_INVALID,
        PrivacyErrorCodes.VALIDATION_CODE_EXPIRED,
        PrivacyErrorCodes.VALIDATION_ABUSE,
        PrivacyErrorCodes.EXPIRED_SUBSCRIPTION,
        PrivacyErrorCodes.NO_SERVICE,
        40031,
        40041,
        40042,
        40051
    ];

    private readonly httpErrorResponseMessage = 'HttpErrorResponse';

    private readonly dataErrorCodesNoRetry = {
        32001: true, // referrral page - invalid parameters
        32005: new Set([
            1108, // devices error
            ...this.privacyErrorCodes,
            39001, // referral - campaign error ex.: expired campaign
            39120, // profile limit exceeded
            ConnectWebmailRegistrationServiceErrorSubCodes.WRONG_CREDENTIALS,
            ConnectWebmailRegistrationServiceErrorSubCodes.INBOX_ALREADY_REGISTERED,
            ConnectWebmailRegistrationServiceErrorSubCodes.NO_FREE_SLOTS,
            PrivacyErrorCodes.EMAIL_APP_NOT_SUPPORTED,
            PrivacyErrorCodes.ACCOUNT_LINKED,
            PrivacyErrorCodes.COMMUNICATION_ERROR
        ])
    };

    constructor(
        @Inject(DOCUMENT) private readonly document: any,
        private readonly cookieService: CookieService,
        private readonly http: HttpClient,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly usefulService: UsefulService,
        private readonly messageService: MessageService,
        private readonly loginLinksService: LoginLinksService,
        private readonly authService: AuthService,
        private readonly handleUnrecoverableStateService: HandleUnrecoverableStateService
    ) {}

    deleteAllCookies() {
        const lang = this.cookieService.get(this.valuesService.cookieLang);

        const usedCookies = [
            this.valuesService.cookieToken,
            this.valuesService.cookieTempToken,
            this.valuesService.cookieEmail,
            this.valuesService.cookieShowAr,
            this.valuesService.cookieUser,
            this.valuesService.cookieShowOffers,
            this.valuesService.cookieShowInvoices
        ];

        for (const cookie of usedCookies) {
            try {
                this.cookieService.delete(cookie, '/');
            } catch { }
        }

        try {
            this.cookieService.deleteAll('/', window.location.hostname);
        } catch { }

        try {
            this.cookieService.deleteAll('/', this.configService.getCookiesDomain());
        } catch { }

        try {
            this.cookieService.deleteAll();
        } catch { }

        if (lang) {
            this.cookieService.set(this.valuesService.cookieLang, lang, null, '/', "", true);
        }
    }

    /**
     * Clears all cookies
     * Used also in auth guard
     */
    clearCookies() {
        this.deleteAllCookies();
        sessionStorage.clear();
        localStorage.clear();
    }

    clearAll(logoutType: LogoutType) {
        deleteServiceWorkerCacheAndLocalStorageVariables();
        this.clearCookies();
        // navigates to login page if no token
        const path = window.location.pathname.concat(window.location.search);
        if (logoutType !== LogoutType.NO_REDIRECT) {
            window.location.href = this.loginLinksService.loginPage(true, true, logoutType, path);
        }
    }

    logout(logoutType: LogoutType) {
        if (!this.markToUpdateLogout) {
            return;
        }

        if (this.onlistLogout$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistLogout$.asObservable()
                .subscribe().add(
                    () => {
                        skipWhile(res => res !== this.valuesService.processServiceState.DONE);
                    }
                );
        } else {
            this.onlistLogout$.next(this.valuesService.processServiceState.INPROGRESS);
            const tkn = this.cookieService.get(this.valuesService.cookieToken);
            const temptkn = this.cookieService.get(this.valuesService.cookieTempToken);
            if (tkn || temptkn) {
                const json = [];
                if (tkn) {
                    json.push({
                        id: parseInt((Math.random() * 100).toString(), 10),
                        jsonrpc: this.valuesService.jsonrpc,
                        method: 'logout',
                        params: {
                            connect_source: {
                                user_token: this.cookieService.get(this.valuesService.cookieToken)
                            }
                        }
                    });
                }

                if (temptkn) {
                    json.push({
                        id: parseInt((Math.random() * 100).toString(), 10),
                        jsonrpc: this.valuesService.jsonrpc,
                        method: 'logout',
                        params: {
                            connect_source: {
                                user_token: this.cookieService.get(this.valuesService.cookieTempToken)
                            }
                        }
                    });
                }

                this.make(this.configService.config.nimbusServer, this.valuesService.loginMgmtService, json, 'POST')
                    .subscribe().add(
                        () => {
                            this.clearAll(logoutType);
                            this.onlistLogout$.next(this.valuesService.processServiceState.DONE);
                            this.markToUpdateLogout = false;
                        }
                    );
            } else {
                this.clearAll(logoutType);
                this.onlistLogout$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateLogout = false;
            }
        }
    }

    private shouldLogout(error): boolean {
        return error?.error?.code === 32004 && error?.error?.message === Errors.WRONG_CREDENTIALS;
    }

    //*
    //* HELPER FUNCTIONS
    //*

    /**
    * This method will check response from server and decide if there are good errors
    * @param {string} service Connect service
    * @param {string} method Connect service method
    * @returns {nothing} Redirects to /500
    */
    private redirect500(service: string, method: string): void {
        const mandatory_services = {
            [this.valuesService.subscriptionMgmtService]: 'list',
            [this.valuesService.connectMgmtService]: 'list_devices_v2',
            [this.valuesService.userinfoMgmtService]: 'getInfo',
            [this.valuesService.userinfoMgmtService]: 'profiles_list'
        };

        if (mandatory_services.hasOwnProperty(service) && method === mandatory_services[service]) {
            this.document.location.href = this.valuesService.centralPaths['500'].path;
        }
    };

    /**
    * This method will check response from server and decide if there are good errors
    * Returns 'false' if retry is not needed for the error or throws error if retry is needed
    * @param {object} data Contains full response from server
    * @returns {boolean} false if no error / error if exists
    */
    private handleConnectError(data, service: string, method: string): boolean {
        if (!data.error && !(data.status && data.status === 'error')) {
            return false;
        }

        if (service === Services.CONNECT_WEBMAIL_PROTECTION && method === Methods.REGISTER_INBOX) {
            return false;
        }

        const dataErrorCode = data?.error?.code;
        const dataErrorDataCode = data?.error?.data?.code;

        const mandatory_services = {
            [this.valuesService.subscriptionMgmtService]: 'list',
            [this.valuesService.connectMgmtService]: 'list_devices_v2',
            [this.valuesService.userinfoMgmtService]: 'getInfo',
            [this.valuesService.userinfoMgmtService]: 'profiles_list'
        };

        if (this.shouldLogout(data)) {
            if (mandatory_services.hasOwnProperty(service) && method === mandatory_services[service] && !this.authService.getHasActiveSession()) {
                this.clearAll(LogoutType.REDIRECT_TO_LOGIN_PAGE);
            } else {
                this.messageService.sendMessage(this.valuesService.events.sessionExpired, {});
            }
            return false;
        } else {
            this.redirect500(service, method);
        }

        if (dataErrorCode) {
            const dataErrorCodeNoRetry = this.dataErrorCodesNoRetry[dataErrorCode];
            if (dataErrorCodeNoRetry === true) {
                return false;
            }

            if (dataErrorCodeNoRetry.has(dataErrorDataCode)) {
                return false;
            }
        }

        if (this.usefulService.checkNested(data, "status") && data.status === 'error') {
            throw {
                status: 'error',
                code: this.errors.service_error,
                message: data.message,
                internal_code: data.code
            };
        } else if (mandatory_services.hasOwnProperty(service) && method === mandatory_services[service]) {
            this.document.location.href = this.valuesService.centralPaths["500"].path;
        } else {
            throw {
                status: 'error',
                code: this.errors.service_error,
                message: data.error.message,
                internal_code: data.error.code,
                internal_data: data.error.data
            };
        }
    }

    successRequest(data, service, params, method) {
        if (this.utilsCommonService.checkArray(data)) { // avem un batch request (deci un array de obiecte) nu un obiect cu raspunsul.
            let errs = false;
            let call;
            for (let i = 0; i < data.length; i++) {
                if (this.shouldLogout(data[i])) {
                    this.messageService.sendMessage(this.valuesService.events.sessionExpired, {});
                }

                if (data[i].error || data[i].status === 'error') {
                    if (data[i].status && data[i].status === 'error') {
                        errs = true;
                        call = {
                            status: 'error',
                            code: this.errors.service_error,
                            message: data[i].message,
                            internal_code: data[i].code
                        };
                    } else if (data[i].error && data[i].error.code === 32005 &&
                                (data[i].error.data === 'Could not find setting'
                                || data[i].error.data.code === 1108
                                || data[i].error.data.code === 1128
                                || data[i].error.data.code === 1129
                                || data[i].error.data.message === 'Article ID not found!'
                                || data[i].error.data.code === 2109
                                || data[i].error.data.code === 32701)) {
                        // nu trebuie sa facem nimic, astea sunt errs bune
                        // errors bune (2109, Article ID not found!) din array-ul de articles din security news - activity page
                    } else {
                        errs = true;
                        call = {
                            status: 'error',
                            code: this.errors.service_error,
                            message: data[i].error.message,
                            internal_code: data[i].error.code,
                            internal_data: data[i].error.data,
                            id: data[i].id
                        };
                    }
                }
            }
            if (errs === false) {
                return data;
            } else {
                this.redirect500(service, params[0].method);
                return call;
            }
        } else {
            let aux = this.handleConnectError(data, service, params.method);
            if (aux) {
                return aux;
            } else {
                if (this.usefulService.checkNested(data, "error", "code") && data.error.code === 32005
                    && this.usefulService.checkNested(data, "error", "data", "code")
                    && (this.privacyErrorCodes.indexOf(data.error.data.code) != -1)) {
                    // when an error happens in privacy, chances are the subscription has expired
                    // so th site should be updated because sometimes dip is standalone
                    this.messageService.sendMessage(PrivacyEvents.SUBSCRIPTION_EXPIRED, { id: 'val', data: {} });
                    return {
                        status: 'error',
                        code: data.error.data.code,
                        message: data.error.data.message,
                        internal_code: data.error.code,
                        data: data.error.data.data,
                        redirect500: false,
                        service: null,
                        method: null
                    };
                }
                //! TBD De verificat daca aici scoatem resultul sau in urmatorul layer
                return data;
            }
        }
    };

    errorRequest(err, service, params, method) {
        if (err.data === null && err.status === 0) {
            err.data = 'Timeout (10 sec)';
            err.status = 408;
        }
        // Experimental - log to server
        let complete_data: any = {};
        complete_data.status = status;
        complete_data.data = err.data;

        if (this.utilsCommonService.checkArray(err)) { // avem un batch request (deci un array de obiecte) nu un obiect cu raspunsul.
            this.redirect500(service, params[0].method);
            return {
                status: 'error',
                code: this.errors.request_status_error,
                message: err.data,
                internal_code: status,
                redirect500: true,
                service: service,
                method: method
            };
        } else {
            this.redirect500(service, params.method);
            if (err.data) {
                return {
                    status: 'error',
                    code: this.errors.request_status_error,
                    message: err.data,
                    internal_code: status,
                    redirect500: true,
                    service: service,
                    method: params.method
                };
            } else {
                return {
                    status: 'error',
                    code: this.errors.request_status_error,
                    message: err,
                    internal_code: status,
                    redirect500: true,
                    service: service,
                    method: params.method
                };
            }
        }
    };

    //*
    //* CORE
    //*

    /**
     * This method is used to process the response from the service worker
     * @param {any|{response: any, failedToFetch: boolean}} response Can be an object or a json {response: any, failedToFetch: boolean}
     * @returns {any} The response from the server
     */
    private processServiceWorkerResponse(response: any|{response: any, failedToFetch: boolean}): void {
        if (response.hasOwnProperty(FailedToFetch)) {
            this.handleUnrecoverableStateService.setFailedToFetchFlag(response.failedToFetch);
            return response.response;
        } else {
            this.handleUnrecoverableStateService.setFailedToFetchFlag(false);
            return response;
        }
    }

    // Metoda care face requestul
    // responseType: 'arraybuffer'|'blob'|'json'|'text', json is default
    public make(link, service, params, method, responseType?): Observable<any> {

        //*
        //* HEADERS CUSTOM
        //*
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };


        if (link.indexOf('nimbus.bitdefender.net') !== -1) {
            httpOptions.headers = httpOptions.headers.set('x-nimbus-clientid', this.configService.config.nimbusClientId );
        }

        if (responseType) {
            httpOptions['responseType'] = responseType;
        }

        //*
        //* VALIDATIONS
        //*

        if (!tv4.validate(link, schemas.schemaURL)) {
            return of({
                status: 'error',
                code: this.errors.wrong_host,
                message: tv4.error.message,
                internal_code: tv4.error.code
            });
        }

        if (!tv4.validate(service, schemas.schemaSERVICE)) {
            return of({
                status: 'error',
                code: this.errors.wrong_service,
                message: tv4.error.message,
                internal_code: tv4.error.code
            });
        }

        if (!tv4.validate(method, schemas.schemaMETHOD)) {
            return of({
                status: 'error',
                code: this.errors.wrong_method,
                message: tv4.error.message,
                internal_code: tv4.error.code
            });
        }

        if (!tv4.validate(params, schemas.schemaJSONorARRAY)) {
            return of({
                status: 'error',
                code: this.errors.wrong_params,
                message: tv4.error.message,
                internal_code: tv4.error.code
            });
        }

        if (method === 'POST') {
            let configPost = {
                url: link + service,
                data: params
            };

            return this.http.post(configPost.url, configPost.data, httpOptions)
            .pipe(
                timeout(10000), //* timeout of request 10 seconds
                map(
                    (res: any) => {
                        /**
                         * this is needed for the service worker to work properly
                         * the response from the service worker is always an array and somethimes the resposne should not be an array, but an object
                         * so we return the first element of the array
                         */
                        res = this.processServiceWorkerResponse(res);
                        if (!Array.isArray(params) && Array.isArray(res)) {
                            return this.successRequest(res[0], service, params, method);
                        } else {
                            return this.successRequest(res, service, params, method);
                        }
                    }
                ),
                retry({ delay: (error, retryCounter) => {

                    // If the error is different from 'HttpErrorResponse' request will be retried one more time
                    // Otherwise, the request will not be retried and an error will be thrown in order to stop retrying
                    if (error?.name !== this.httpErrorResponseMessage && retryCounter === 1) {
                        return of(error);
                    }

                    throw error;
                }}),
                catchError(err => {
                    throw this.errorRequest(err, service, params, method);
                })
            );
        } else {
            let conf = {
                url: link + service + '?' //+ param(params)
            };

            return this.http.get(conf.url, httpOptions)
            .pipe(
                timeout(10000), //* timeout of request 10 seconds
                map(
                    (res: any) => {
                        return this.successRequest(res, service, params, method);
                    }
                ),
                retry(1), //* retry after request error (! NOT connect error)
                catchError((err) => {
                    throw this.errorRequest(err, service, params, method);
                })
            );
        }

    }

}
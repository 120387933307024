import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject, takeUntil } from 'rxjs';
import { DynamicProperties } from '../../../../common/models/Pwa.model';
import {
    FailedToFetch,
    ServiceWorkerIsNoLongerResponsive,
    UserShouldNotTryToWriteToDataBase,
    StorageTrueValue,
    ValuesService
} from '../../../../common/values/values.service';
import { MessageService } from '../../core/message.service';
import { deleteServiceWorkerCacheAndLocalStorageVariables } from '../request-service/requests.service';
import { getPwaActive } from '../../../../../app/app.module';

@Injectable({providedIn: 'root'})

export class HandleUnrecoverableStateService implements OnDestroy {
    private unrecoverableState = false;
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly swUpdate: SwUpdate,
        private readonly valuesService: ValuesService,
        private readonly messageService: MessageService
    ) {
        if (getPwaActive()) {
            this.swUpdate.unrecoverable
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(event => {
                this.unrecoverableState = true;
                console.log(
                    'An error occurred that we cannot recover from:\n',
                    event.reason,
                    '\n\nPlease reload the page.'
                );
            });
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Method used for checking if the service worker is broken by hard refresh or by hard refresh + clear cache.
     * In this case, the registration is still present, but the controller is null.
     * @param {boolean} refresh Flag used for displaying the broken state banner. If user refreshed the page, the service worker is broken
     * and there are no connectivity problems,
     * the banner will be displayed.
     */
    public checkIfServiceWorkerIsBroken(refresh: boolean): void {
        if (DynamicProperties.SERVICE_WORKER in navigator) {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (getPwaActive()) {
                    if (!navigator.serviceWorker.controller && registration) {
                        this.setServiceWorkerIsUnavailable(true);
                    } else {
                        this.markToupdateRequestsAndDatabaseForWrintingOneTime();
                        if (refresh && !this.thereAreConnectivityProblems()) {
                            this.setServiceWorkerIsUnavailable(false);
                        }
                    }
                } else {
                    deleteServiceWorkerCacheAndLocalStorageVariables();
                    this.resetUnrecoverableStateFlag();
                    this.deleteCache();
                    registration?.unregister();
                }
            });
        }
    }

    /**
     * Method used for deleting the cache.
     */
    private deleteCache(): void {
        caches?.keys().then(cacheNames => {
            return Promise.all(
                cacheNames.map(cacheName => {
                    return caches.delete(cacheName);
                })
            );
        });
    }

    /**
     * Method used for marking the requests so the requests will be written to the database.
     * Because we want it one time, the first navigation after the sw is activated, we mark it in the local storage
     * with a flag so the reload is made only once.
     */
    private markToupdateRequestsAndDatabaseForWrintingOneTime(): void {
        const userShouldNotTryToWriteToDataBase = localStorage.getItem(UserShouldNotTryToWriteToDataBase);
        if (!userShouldNotTryToWriteToDataBase || userShouldNotTryToWriteToDataBase !== StorageTrueValue) {
            localStorage.setItem(UserShouldNotTryToWriteToDataBase, StorageTrueValue);
            this.messageService.sendDynamicSubjectMessage(this.valuesService.events.resetRequestsForServiceWorker, {});
        }
    }

    /**
     * Method used for setting the flag in the local storage for the service worker is no longer detecting new versions/is broken.
     * @param {boolean} value Value to set the flag in the local storage
     */
    public setServiceWorkerIsUnavailable(value: boolean): void {
        localStorage.setItem(ServiceWorkerIsNoLongerResponsive, value.toString());
    }

    /**
     * Method used for getting the flag from the local storage for the service worker is no longer detecting new versions/is broken.
     * @returns {boolean} Returns true if the service worker is no longer detecting new versions/is broken, false otherwise.
     */
    public getServiceWorkerIsUnavailable(): boolean {
        return localStorage.getItem(ServiceWorkerIsNoLongerResponsive) === StorageTrueValue;
    }

    /**
     * Method used for checking if there are connectivity problems and displaying a message to the user.
     * @returns {boolean} Returns true if there are connectivity problems, false otherwise.
     */
    public thereAreConnectivityProblems(): boolean {
        return localStorage.getItem(FailedToFetch) === StorageTrueValue && !!navigator?.serviceWorker?.controller && getPwaActive();
    }

    /**
     * Method used for setting the flag in the local storage for the connectivity problems.
     * @param value Value to set the flag in the local storage.
     */
    public setFailedToFetchFlag(value: boolean): void {
        localStorage.setItem(FailedToFetch, value.toString());
    }

    /**
     * Method used for getting the flag for the unrecoverable state.
     * @returns {boolean} Returns true if the app is in unrecoverable state, false otherwise.
     */
    public getUnrecoverableStateFlag(): boolean {
        return this.unrecoverableState;
    }

    /**
     * Method used for resetting the flag for the unrecoverable state.
     */
    public resetUnrecoverableStateFlag(): void {
        this.unrecoverableState = false;
    }
}
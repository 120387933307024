// External
import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, mergeMap, skipWhile } from 'rxjs/operators';

// Internal
import { ConnectNotificationsService } from '../../requests/connect-notifications/connect-notifications.service';
import { SeccenterService } from '../../requests/connect-seccenter-service/connect-seccenter.service';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { UsefulService } from '../../global/useful/useful.service';
import { MessageService } from '../../core/message.service';
import { ConfigService } from '../../../../common/config/config.service';
import { NotificationsSubtypes, NotificationsTypes } from '../../global/events/events.model';

export enum NotificationDrawerStatus {
    DETAILS = 'details',
    LIST = 'list',
    NULL = 'null'
}

@Injectable({
    providedIn: 'root'
})

export class NotificationsService {

    private readonly onListNotifications$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    markToUpdate_notifications = true;

    private readonly onGetUnread$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    markToUpdate_unread = true;

    invokeEventForNotifs: Subject<any> = new Subject();
    invokeEventFromDrawer: Subject<any> = new Subject();
    private readonly invokeShowNotificationDetailsEvent: Subject<any> = new Subject();
    notificationInfoThreats: any;
    notifications: any = {
        list: [],
        ids: new Set(),
        count: 0
    };
    newNotificationsIds = [];
    vars = {
        count: 15,
        offset: 0,
        final: 0
    };

    sendLocate = false;
    private _notificationsDrawerStatus = NotificationDrawerStatus.NULL;
    _notificationType = null;
    _notificationSubType = null;

    constructor(
        private readonly connectnotificationsService: ConnectNotificationsService,
        private readonly seccenterService: SeccenterService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService,
        public readonly usefulService: UsefulService,
        private readonly messageService: MessageService,
        private readonly configService: ConfigService
    ) { }

    // Functie care verifica daca jsonul de notificare corespunde cu valorile venita pe notificare
    private validateNotif(notification) {
        const notificationType = notification?.app_fields?.notification_params?.notification_type;
        const notificationSubtype = notification?.app_fields?.notification_params?.notification_subtype;
        return !!(this.valuesService.notificationModule?.[notificationType]?.[notificationSubtype]
                    || this.valuesService.notificationModule?.[notificationType]?.[NotificationsSubtypes.DEFAULT]);
    }

    //! Functions to get and set notifications status
    //! Should be moved in another servcice that is not in process
    setDrawerStatus(status: NotificationDrawerStatus) {
        this._notificationsDrawerStatus = status;
    }
    getDrawerStatus() {
        return this._notificationsDrawerStatus;
    }
    setNotificationType(type, subtype){
        this._notificationType = type;
        this._notificationSubType = subtype;
    }
    getNotificationType(){
        return {
            type: this._notificationType,
            subType: this._notificationSubType
        };
    }
    //!

    noListing() {
        return !this.configService.getNotifications();
    }

    listNotifications(loadMore?): Observable<any> {
        return this.getNotifications(loadMore)
        .pipe(
            mergeMap(() => {
                return this.listNotificationsByIds();
            }
        ));
    }

    getNotifications(isLoadMore?): Observable<any> {
        if (!(this.markToUpdate_notifications || isLoadMore === true) || this.noListing()) {
            return of(this.notifications);
        }

        if (this.onListNotifications$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListNotifications$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListNotifications$.next(this.valuesService.processServiceState.INPROGRESS);

            // notifications.lost should be changed only in functions that are protected, so no 2 threads can modify the same object
            // and overwrite eachothers changes
            if (this.markToUpdate_notifications) {
                this.vars.offset = 0;
                this.notifications.list = [];
                this.notifications.ids.clear();
                this.notifications.count = 0;
                //! aici daca eu am 60 de notificari si vine un event ca s-a citit o notificare,
                //! ar trebui sa golesc lista la event si sa pun count pe 0
                //! dar intrebarea e
                //! ar trebui sa cer 60 de notificari(daca am offset dif de zero)
                //! sau sa cer doar primele 15 ???
            }

            return this.connectnotificationsService.getNotifications(this.vars)
                .pipe(
                    map((resp: any) => {
                        if (resp) {
                            for (const notification of resp) {
                                if (this.validateNotif(notification)) {
                                    this.notifications.list.push(notification);
                                    this.notifications.ids.add(notification?.app_fields?.notification_id);
                                }
                            }

                            const newNotificationsCounter = resp.length ? resp.length : 0;
                            if (!newNotificationsCounter || newNotificationsCounter < this.valuesService.notificationsPageCounter) {
                                this.notifications.final = true;
                            }

                            this.vars.offset = this.vars.offset + newNotificationsCounter;
                            this.markToUpdate_notifications = false;
                            this.onListNotifications$.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.markToUpdate_notifications = true;
                            this.onListNotifications$.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                    }),
                    catchError( () => {
                        this.markToUpdate_notifications = true;
                        this.onListNotifications$.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    }
                ));
        }
    }

    filtereAlreadyPresentNotifications() {
        const filteredNotificationsIds = [];
        for (const notificationId of this.newNotificationsIds) {
            if (!this.notifications.ids.has(notificationId)) {
                filteredNotificationsIds.push(notificationId);
            }
        }
        this.newNotificationsIds = filteredNotificationsIds;
    }

    listNotificationsByIds(): Observable<any> {
        this.filtereAlreadyPresentNotifications();

        if (this.utilsCommonService.isEmptyArray(this.newNotificationsIds) || this.noListing()) {
            return of(this.notifications);
        }

        if (this.onListNotifications$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListNotifications$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListNotifications$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectnotificationsService.getNotificationById(this.newNotificationsIds)
                .pipe(
                    map((resp: any) => {
                        if (resp) {
                            let valid_notifs = [];
                            for (const notification of resp) {
                                if (this.validateNotif(notification)) {
                                    valid_notifs.push(notification);
                                    this.notifications.ids.add(notification?.app_fields?.notification_id);

                                    // e nevoie de informatiile ce vin pe notificari pt ca din connect nu vine event cand locatia nu se schimba
                                    // si apare eroare in antitheft la locate
                                    if (notification?.app_fields?.notification_params?.notification_type === NotificationsTypes.ANTITHEFT
                                        && notification?.app_fields?.notification_params?.notification_subtype === NotificationsSubtypes.DEVICE_LOCATED
                                        && notification?.app_fields?.connect_source?.device_id) {
                                        const deviceId = notification?.app_fields?.connect_source?.device_id.toString();
                                        const options = JSON.parse(JSON.stringify({...notification?.app_fields?.notification_params, timestamp: notification?.app_fields.timestamp}));
                                        this.messageService.sendMessage(this.valuesService.events.sendLocate.concat('-', deviceId), options);
                                    }
                                    this.messageService.sendMessage(this.valuesService.events.createPersistentNotification, notification);
                                }
                            }
                            const aux = valid_notifs.concat(this.notifications.list);
                            this.notifications.list = aux;
                            this.notifications.count += resp.length;

                            this.newNotificationsIds = [];
                            this.onListNotifications$.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.onListNotifications$.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                    }),
                    catchError(err => {
                        this.onListNotifications$.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    }
                ));
        }
    }

    readNotification(notification) {
        return this.connectnotificationsService.readNotification(notification.app_fields.notification_id)
        .pipe(
            map( () => {
                if (!notification.ack) {
                    this.notifications.count -= 1;
                    notification.ack = true;
                    this.messageService.sendMessage(this.valuesService.events.notificationsCounterChange, {});
                }
            })
        );
    }

    countUnreadNotifications(): Observable<any> {
        if (!this.markToUpdate_unread || this.noListing()) {
            return of(this.notifications);
        }

        if (this.onGetUnread$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onGetUnread$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onGetUnread$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectnotificationsService.countNotifications()
                .pipe(
                    map((resp: any) => {
                        if (resp) {
                            this.notifications.count = JSON.parse(JSON.stringify(resp.count));
                            this.markToUpdate_unread = false;
                            this.messageService.sendMessage(this.valuesService.events.notificationsCounterChange, {});
                            this.onGetUnread$.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.markToUpdate_unread = true;
                            this.onGetUnread$.next(this.valuesService.processServiceState.DONE);
                            return of('malformed_request');
                        }
                    }),
                    catchError(error => {
                        this.markToUpdate_unread = true;
                        this.onGetUnread$.next(this.valuesService.processServiceState.DONE);
                        throw error;
                    }
                ));
        }
    }

    listThreatsInfo(): Observable<any> {
        const threats = [];
        const notifs = this.notifications.list;

        if (this.noListing()) {
            return of(threats);
        }

        for (const i in notifs) {
            const has_id = notifs[i].app_fields.notification_params.threat_group_id;
            const has_count = notifs[i].app_fields.notification_params.threats_count;
            const has_request = notifs[i].app_fields.notification_params.threats_requested;

            if (has_id && has_count && (!has_request || (has_request && !notifs[i].app_fields.notification_params.threats_requested))) {
                threats.push({
                    threat_group_id: notifs[i].app_fields.notification_params.threat_group_id,
                    threats_count: notifs[i].app_fields.notification_params.threats_count,
                    notification_id: notifs[i].app_fields.notification_id
                });
            }
        }

        if (threats.length !== 0) {
            return this.seccenterService.threatsInfo(threats)
                .pipe(
                    map((resp: any) => {
                        if (!Array.isArray(resp)) {
                            return;
                        }

                        for (const threat of resp) {
                            const data = threat.data;
                            for (const notif of notifs) {
                                if (notif.app_fields.notification_id !== threat.notification_id) {
                                    continue;
                                }

                                const errorCode = data.code;
                                if(errorCode) {
                                    notif.app_fields.notification_params.error_code = errorCode;
                                    break;
                                }
                                notif.app_fields.notification_params.threat_details = data;
                                notif.app_fields.notification_params.threats_requested = true;
                                break;
                            }
                        }
                    }),
                    catchError(() => {
                        for(const threat of threats) {
                            for (const notif of notifs) {
                                if (notif.app_fields.notification_id === threat.notification_id) {
                                    notif.app_fields.notification_params.error_code = 'default';
                                    break;
                                }
                            }
                        }
                        return of(true);
                    })
                );
        } else {
            return of(threats);
        }
    }

    get() {
        return this.notifications;
    }

    getNotificationsList() {
        const notifications = this.notifications.list;
        return notifications ? notifications : [];
    }

    getNotificationsCounter() {
        return this.notifications.count;
    }

    sendEventNotifs(event) {
        this.invokeEventForNotifs.next(event);
    }

    sendEventFromDrawer () {
        this.invokeEventFromDrawer.next('true');
    }

    /**
     * Send event to show notification details.
     * @param notification - notification
     */
    public sendShowNotificationDetailsEvent(notificaiton): void {
        this.invokeShowNotificationDetailsEvent.next(notificaiton);
    }

     /**
     * Gets the observable for the event to show notification details.
     */
     public getShowNotificationDetailsEvent(): Observable<any> {
        return this.invokeShowNotificationDetailsEvent;
    }

    updateNotifications(notificationsIds) {
        this.newNotificationsIds = this.newNotificationsIds.concat(notificationsIds);
    }

    updateAllNotifications() {
        if (this.onListNotifications$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_notifications = true;
        }
    }

    updateUnread() {
        if (this.onGetUnread$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_unread = true;
        }
    }

}

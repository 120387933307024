// External
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

// Internal
import { UtilsCommonService } from '../utils/utils-common.service';
import { LanguageService } from '../services/core/language.service';
import { DevicesValuesService } from '../values/devices.values';
import { ValuesService } from '../values/values.service';
import { ParentalNccValuesService } from '../values/parental-ncc.values.service';
import { PrivacyValuesService } from '../values/privacy.values.service';
import { timestamp } from 'rxjs';

@Pipe({
    name: 'hourMinuteInterval',
})
export class HourMinuteInterval implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) {}
    transform(value) {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        return this.translateService.instant('parental.daily.time.limit.hours.minutes', {hours, minutes});
    }
}

@Pipe({
    name: 'hoursFromSeconds',
})
export class HoursFromSeconds implements PipeTransform {
    transform(value) {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        let hoursString = hours.toString();
        if (hoursString.length === 1) {
            hoursString = '0'.concat(hoursString);
        }

        let minutesString = minutes.toString();
        if (minutesString.length === 1) {
            minutesString = '0'.concat(minutesString);
        }

        return hoursString.concat(":", minutesString);
    }
}

@Pipe({
    name: 'filterByPropertyPipe'
})
export class FilterByPropertyPipe implements PipeTransform {

    transform(array: any[], filter: {propertyName: string; operator: 'eq' | 'notEq' | 'includes' | 'iincludes'; value: any}): any[] {
        if (!Array.isArray(array)) {
            return;
        }

        if (!filter) {
            return array;
        }

        switch(filter.operator) {
            case 'eq':
                array = array.filter((x) => x[filter.propertyName] === filter.value);
                break;
            case 'notEq':
                array = array.filter((x) => x[filter.propertyName] !== filter.value);
                break;
            case 'includes':
                array = array.filter((x) => x[filter.propertyName].includes(filter.value));
                break;
            case 'iincludes':
                array = array.filter((x) => x[filter.propertyName].toLowerCase().includes(filter.value.toLowerCase()));
                break;
        }

        return array;
    }
}


@Pipe({
    name: 'orderByPropertyPipe'
})
export class OrderByPropertyPipe implements PipeTransform {

    transform(array: any[], orderBy: {propertyName: string; direction: 'asc' | 'desc'}): any[] {
        if (!Array.isArray(array)) {
            return;
        }

        if (!orderBy) {
            return array;
        }

        const isString = typeof (array?.[0]?.[orderBy.propertyName]) === 'string';

        if (orderBy.direction === 'asc') {
            if (isString) {
               array = array.sort((a, b) => (a?.[orderBy.propertyName]?.toLowerCase() <= b?.[orderBy.propertyName]?.toLowerCase()) ? -1 : 1);
            } else {
               array = array.sort((a, b) => (a?.[orderBy.propertyName] <= b?.[orderBy.propertyName]) ? -1 : 1);

            }
        } else {
            if (isString) {
               array = array.sort((a, b) => (a?.[orderBy.propertyName]?.toLowerCase() >= b?.[orderBy.propertyName]?.toLowerCase()) ? -1 : 1);
            } else {
               array = array.sort((a, b) => (a?.[orderBy.propertyName] >= b?.[orderBy.propertyName]) ? -1 : 1);

            }
        }

        return array;
    }
}
@Pipe({
    name: 'count'
})
export class CountPipe implements PipeTransform {
    transform(array: any[], field?: {name: string; value: any}): number {
        if (!Array.isArray(array)) {
            return;
        }

        if (!field) {
            return array.length;
        }

        let count = 0;
        for (let item of array) {
            if (item[field.name] === field.value) {
                count = count + 1;
            }
        }
        return count;
    }
}

@Pipe({
    name: 'timeFromMiliseconds'
})
export class TimeFromMilisecondsPipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) {}

    transform(miliseconds: number): string {
        if (!miliseconds || miliseconds < 1) {
            return this.translateService.instant('parental.time.display.hm', {hours: 0, minutes: 0});
        }

        const seconds = Math.floor(miliseconds / 1000);

        let days   = Math.floor(seconds / 86400);
        let hours   = Math.floor(seconds % 86400 / 3600);
        let minutes = Math.floor(seconds % 3600 / 60);

        if (days < 1) {
            if (hours < 1) {
                return this.translateService.instant('parental.time.display.m', {minutes: minutes});
            }
            return this.translateService.instant('parental.time.display.hm', {hours: hours, minutes: minutes});
        }
        return this.translateService.instant('parental.time.display.dh', {days: days, hours: hours});
    }
}

@Pipe({
    name: 'timeFromSeconds'
})
export class TimeFromSecondsPipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) {}

    transform(seconds: number): string {
        if (!seconds || seconds < 1) {
            return this.translateService.instant('parental.time.display.hm', {hours: 0, minutes: 0});
        }

        let days   = Math.floor(seconds / 86400);
        let hours   = Math.floor(seconds % 86400 / 3600);
        let minutes = Math.floor(seconds % 3600 / 60);

        if (days < 1) {
            if (hours < 1) {
                return this.translateService.instant('parental.time.display.m', {minutes: minutes});
            }
            return this.translateService.instant('parental.time.display.hm', {hours: hours, minutes: minutes});
        }
        return this.translateService.instant('parental.time.display.dhm', {days: days, hours: hours, minutes: minutes});
    }
}

@Pipe({
    name: 'parentalMore'
})
export class ParentalMorePipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) {}

    transform(items, noToDisplay: number): string {
        if (!items) {
            return '';
        }

        let result = '';
        for (let i = 0; i < noToDisplay; i++) {
            if (i === items.length) {
                break;
            } else if (i > 0) {
                if (i + 1 === items.length) {
                    result = result + ' ' + this.translateService.instant('parental.key.and') + ' ';
                } else {
                    result = result + ', ';
                }
            }

            result = result + items[i].name;
        }

        const undisplayedItemsNo = items.length - noToDisplay;
        if (undisplayedItemsNo > 0) {
            result = result +  ' ' + this.translateService.instant('parental.key.andMore', { number: undisplayedItemsNo});
        }
        return result;
    }
}


@Pipe({
    name: 'topicsMore'
})
export class TopicsMorePipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService,
        private readonly parentalNccValueService: ParentalNccValuesService
    ) {}

    transform(items: string[], noToDisplay: number, end?: boolean): string {
        if (!items) {
            return '';
        }

        let result = '';
        if (items.length > noToDisplay && !end) {
            for (let i = 0; i < noToDisplay; i++) {
                result = result + this.translateService.instant(this.parentalNccValueService.contentFilteringCategoriesNames[items[i]]);
                if (i < noToDisplay - 1) {
                    result = result + ', ';
                }
            }
        } else if (!end) {
            for (let i = 0; i < items.length; i++) {
                result = result + this.translateService.instant(this.parentalNccValueService.contentFilteringCategoriesNames[items[i]]);
                if (i < items.length - 1) {
                    result = result + ', ';
                }
            }
        } else if (end && items.length > noToDisplay) {
            for (let i = noToDisplay; i < items.length; i++) {
                result = result + this.translateService.instant(this.parentalNccValueService.contentFilteringCategoriesNames[items[i]]);
                if (i < items.length - 1) {
                    result = result + ', ';
                }
            }
        }
        return result;
    }
}
@Pipe({
    name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
    transform(value, type) {
        if (!type) {
            type = 'dd/MM/yyyy';
        }
        let datePipe = new DatePipe('en-US');
        value = datePipe.transform(value, type);
        return value;
    }
}
@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(
        private readonly translateService: TranslateService,
        private readonly languageService: LanguageService) {}

    transform(value: any, pattern: string, compute?: boolean): any {

        if(!value) {
            return '';
        }

        if (compute) {
            let currentDay: any = new Date();
            currentDay.setHours(23, 59, 59);

            const dif = (currentDay - new Date(value).getTime()) / (60 * 60 * 24 * 1000);
            const timestamp: number = parseInt(dif.toString(), 10);
            const date = moment(new Date(value)).format('HH:mm');

            if (timestamp === 0 && dif > 0) {
                return this.translateService.instant('notifications.main.today.at', {date: date});
            } else if (timestamp === 0 && dif < 0) {
                return this.translateService.instant('notifications.main.tomorrow.at', {date: date});
            } else if (timestamp === 1) {
                return this.translateService.instant('notifications.main.yesterday');
            }
        }

        const formats = {
            'MMM, d': { day: 'numeric', month: 'short' },
            'mediumDate': { year: 'numeric', month: 'short', day: 'numeric' },
            'mediumDateHourMin': { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' },
            'mediumDateHourMinSec': { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
            'hourMinute': { hour: 'numeric', minute: 'numeric' },
            'hour': { hour: 'numeric' },
            'MMMM d, y': { year: 'numeric', month: 'long', day: 'numeric' },
            'MMMM y': { year: 'numeric', month: 'long' }
        }

        const valueTimestamp = new Date(value).getTime();
        const langFormat = this.languageService.getLang().replace('_', '-');
        const options:  Intl.DateTimeFormatOptions = formats[pattern];
        const finalDateNew = new Intl.DateTimeFormat(langFormat, options).format(valueTimestamp);
        return finalDateNew;
    }
}

@Pipe({
    name: 'identitiesCounter',
    pure: false
})
export class IdentitiesCounterPipe implements PipeTransform {

    constructor(
            private readonly translateService       : TranslateService,
            private readonly privacyValuesService   : PrivacyValuesService
        ) {}

    reallyBigNumbers(value) {
        let newValue = value;
        let multiplier = 1;
        let keyString = "million";
        // 1 000 000
        if (value >= 1000000) {
            multiplier = 1;
            keyString = "million";
        }

        // 1 000 000 000
        if (value >= 1000000000) {
            multiplier = 1000;
            keyString = "billion";
        }

        // 10 000 000 * multiplier
        if (value < 10000000*multiplier) {
            // 100 000 * multiplier
            newValue = (Math.floor(value/(100000*multiplier)) / 10).toString().replace(".", ",");
        } else {
            // 1 000 000 * multiplier
            newValue = Math.floor(value/(1000000*multiplier)).toString();
        }

        return this.translateService.instant(this.privacyValuesService.privacyBreachesIndentities[keyString], {number: newValue});
    }

    transform(value: any): any {
        if (value === 1) {
            return this.translateService.instant('dashboard.privacy.breaches.indentity');
        }

        if (value < 1000) {
            return this.translateService.instant('dashboard.privacy.breaches.indentities', {number: value});
        }

        // 1 000 000
        if (value < 1000000) {
            const newValue = (value/1000).toFixed(3).toString().replace(".", ",");
            return this.translateService.instant('dashboard.privacy.breaches.indentities', {number: newValue});
        }

        return this.reallyBigNumbers(value);
    }
}

@Pipe({
    name: 'toHours'
})
export class ToHours implements PipeTransform {
    transform(value: number): string {
        if (value > 0 && value / 60 < 1) {
            return '1';

        } else {
            return (value / (60 * 60)).toString();
        }
    }
}

@Pipe({
    name: 'alphabetically'
})
export class Alphabetically implements PipeTransform {
    transform(value) {
        value.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1);
        return value;
    }
}

@Pipe({
    name: 'sortedLimits'
})
export class SortedLimits implements PipeTransform {
    transform(value) {
        let limitsValues = Object.values(value);
        limitsValues.sort(function (a, b) {
            let textIndex_a = a["textIndex"];
            let textIndex_b = b["textIndex"];
            if (Object.keys(textIndex_a)[0] < Object.keys(textIndex_b)[0]) {
                return -1;
            } else {
                return 1;
            }
        });

        let new_limits = {};
        for (let value of limitsValues) {
            new_limits[value["restriction_id"]] = value;
        }
        return new_limits;
    }
}

@Pipe({
    name: 'groupExposedData'
})
export class GroupExposedData implements PipeTransform {
    constructor(
        private translateService: TranslateService,
    ) {}
    transform(values) {
        let newValues = [];
        let tempName = [];
        for(let i = 0; i < values.length; i++) {
            if(i < 3) {
                newValues.push(values[i]);
            } else {
                tempName.push(' ' + this.translateService.instant(values[i].name));
            }
        }
        if(values.length === 4) {
            newValues.push(values[3]);
        }
        if (values.length > 4) {
            newValues.push({
                categ: "other",
                icon: "ellipsis-circled",
                name: tempName,
                value: undefined,
                group: true
            });
        }
        return newValues;
    }
}

@Pipe({
    name: 'translateList',
  })
  export class TranslateListPipe extends TranslatePipe implements PipeTransform {
    transform(key: any, length: number): any[] {
      return [...Array(length).keys()].map((i) => super.transform(`${key}.${i}`));
    }
  }
@Pipe({
    name: 'timeAgoDatePipe',
    // pure: false // linia asta ajuta ca pipe-ul sa se actualizeze cand se schimba limba
})
export class TimeAgoDatePipe implements PipeTransform {
    constructor(
        private translateService: TranslateService,
        private readonly languageService: LanguageService
    ) {}
    // changes is used when language changes, so texts will pe translated again
    transform(value, changes) {
        let date = new Date();
        let time = date.getTime();

        const deltaValue = (time - value) / 1000;
        let result = "";
        if (deltaValue < 1) {
            result = this.translateService.instant('protection.time.issues', {number: 1, timeSegment: this.translateService.instant('protection.time.second')});
        } else if (deltaValue < 60) {
            result = this.translateService.instant('protection.time.issues', {number: Math.floor(deltaValue), timeSegment: this.translateService.instant('protection.time.seconds')});
        } else if (deltaValue < 120) {
            result = this.translateService.instant('protection.time.issues', {number: 1, timeSegment: this.translateService.instant('protection.time.minute')});
        } else if (deltaValue < 3600) {
            result = this.translateService.instant('protection.time.issues', {number: Math.floor(deltaValue/60), timeSegment: this.translateService.instant('protection.time.minutes')});
        } else if (deltaValue < 7200) {
            result = this.translateService.instant('protection.time.issues', {number: 1, timeSegment: this.translateService.instant('protection.time.hour')});
        } else if (deltaValue < 3600 * 24) {
            result = this.translateService.instant('protection.time.issues', {number: Math.floor(deltaValue/3600), timeSegment: this.translateService.instant('protection.time.hours')});
        } else if (deltaValue < 3600 * 24 * 2) {
            // intre 1 zi si 2 zile (1 zi si ceva, o sa apara 1 zi)
            result = this.translateService.instant('protection.time.issues', {number: 1, timeSegment: this.translateService.instant('protection.time.day')});
        } else if (deltaValue < 3600 * 24 * 30) {
            // mai mult de 2 zile, mai putin de 1 luna
            result = this.translateService.instant('protection.time.issues', {number: Math.floor(deltaValue/(3600*24)), timeSegment: this.translateService.instant('protection.time.days')});
        } else if (deltaValue < 3600 * 24 * 365) {
            // mai mult de 1 luna, mai putin de 1 an

            const langFormat = this.languageService.getLang().replace('_', '-');
            const options:  Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
            result = new Intl.DateTimeFormat(langFormat, options).format(value);

        } else {
            // mai mult de 1 an
            const date = new Date(value);
            const datePipe = new DatePipe(this.languageService.getLang());
            result = datePipe.transform(date, "dd MMM YYYY");

            const langFormat = this.languageService.getLang().replace('_', '-');
            const options:  Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
            result = new Intl.DateTimeFormat(langFormat, options).format(value);
        }
        return result;
    }
}

/**
 * Method return days difference between current date and a specific date
 * @export
 * @class DaysAgoPipe
 * @implements {PipeTransform}
 * timestamp === 0 && dif > 0: today
 * timestamp === 0 && dif < 0: tomorrow
 * timestamp === 1: yesterday
 */
@Pipe({
  name: 'daysAgoPipe',
  pure: false
})
export class DaysAgoPipe implements PipeTransform {

    constructor() {}

    transform(value: any): number {

        if (!value) {
            return;
        }

        const currentDay: any = new Date();
        currentDay.setHours(23, 59, 59);

        const dif = (currentDay - new Date(value).getTime()) / (60 * 60 * 24 * 1000);
        const timestamp: number = parseInt(dif.toString(), 10);

        return timestamp;
    }
}

// this is a hack. We have to change this because in KB we don't have the bitdefender.com domain (we have a relative path) !!!!!!!
@Pipe({ name: 'decodeBitdefendercomhtml' })
export class DecodeBitdefendercomHtmlPipe implements PipeTransform {

    constructor(private sanitized: DomSanitizer) { }
    transform(htmlCode) {
        let txt = document.createElement('textarea');
        txt.innerHTML = htmlCode;
        txt.value = txt.value.replace(/src="\/images\//gi, 'src="https://download.bitdefender.com/resources/images/');
        txt.value = txt.value.replace(/text-align: center/g, 'text-align: justify');
        txt.value = txt.value.replace(/<p style="text-align: justify;">&nbsp;<\/p>/g, '');
        return this.sanitized.bypassSecurityTrustHtml(txt.value);
    }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: any, args?: any): any {
        return this.sanitizer.bypassSecurityTrustHtml(value);
        // return this.sanitizer.bypassSecurityTrustStyle(style);
        // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
    }
}


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}


@Pipe({
    name: 'valueArray',
})
export class ValueArrayPipe implements PipeTransform {
    transform(value) {
        if (Array.isArray(value)) {
            return Array.from(value);
        } else {
            return Array.from([value]);
        }
    }
}

@Pipe({
    name: 'first'
})
export class FirstKeyPipe {
    transform(obj) {
        var keys = Object.keys(obj);
        if (keys && keys.length > 0) {
            return keys[0];
        }
        return null;
    }
}

@Pipe({
    name: 'callbackItem',
    pure: false
})
export class CallbackItemPipe implements PipeTransform {
    transform(items: any[], callback: (item: any) => boolean): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter(item => callback(item));
    }
}

@Pipe({
    name: 'callbackCount',
    pure: false
})
export class CallbackCountPipe implements PipeTransform {
    transform(items: any, callback: (item: any) => boolean): any {
        if (!items || !callback) {
            return items;
        }
        if (!(items instanceof Array)) {
            items = [items];
        }

        return items.filter(item => callback(item)).length;
    }
}


@Pipe({
    name: 'hexToRgba',
})
export class HexToRgba {
    transform(item, opacity?) {

        let r, g, b;
        let itemArr = item.split("");

        let div = (itemArr.length - 1) / 3;
        r = "0x" + itemArr[1] + itemArr[div];
        g = '0x' + itemArr[1 + div] + itemArr[div * 2];
        b = '0x' + itemArr[1 + div * 2] + itemArr[div * 3];

        // // 3 digits
        // if (itemArr.length === 4) {
        //   r = "0x" + itemArr[1] + itemArr[1];
        //   g = "0x" + itemArr[2] + itemArr[2];
        //   b = "0x" + itemArr[3] + itemArr[3];

        // // 6 digits
        // } else if (itemArr.length === 7) {
        //   r = "0x" + itemArr[1] + itemArr[2];
        //   g = "0x" + itemArr[3] + itemArr[4];
        //   b = "0x" + itemArr[5] + itemArr[6];
        // }

        return "rgba(" + +r + "," + +g + "," + +b + "," + (opacity ? opacity : 1) + ")";
    }
}


@Pipe({
    name: 'truncateText',
})
export class TruncateText implements PipeTransform {
    transform(string: string, length: number): string {
        const ellipsis = '...';
        if (string.length - length <= ellipsis.length) {
            return string;
        }

        return string.substring(0, length).concat(ellipsis);
    }
}

/**
* Pipe used for converting date to specific format with superscript ( ex: 29th )
* @param {number} timestamp
* @returns {html} Html string with modified date
*/
@Pipe({
    name: 'newsDate',
})
export class NewsDate implements PipeTransform {

    constructor(
        private datePipe: DatePipe,
        private sanitizer: DomSanitizer
    ) { }

    transform(string, length) {
        var suffixes = ["<sup>th</sup>", "<sup>st</sup>", "<sup>nd</sup>", "<sup>rd</sup>"];
        if (!string) {
            return;
        }
        let dtfilter = this.datePipe.transform(string, 'MMMM dd');
        var day = parseInt(dtfilter.slice(-2));
        var relevantDigits = (day < 30) ? day % 20 : day % 30;
        var suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
        return this.sanitizer.bypassSecurityTrustHtml(String(dtfilter + suffix + this.datePipe.transform(string, ' yyyy')));
    }
}

//! ar fi frumos sa dam ca params 'value' si 'first_name' ca sa poata fi generalizata
@Pipe({
    name: 'orderValuesByName',
})
export class OrderValuesByNamePipe implements PipeTransform {
    constructor(private utilsService: UtilsCommonService) { }
    transform(object): any {
        let keys = [];
        for (let key in object) {
            if (!this.utilsService.isEmptyObject(object[key])) {
                keys.push({ key: key, value: object[key] });
            }
        }
        keys.sort((a, b) => a['value']['first_name'].toLowerCase().localeCompare(b['value']['first_name'].toLowerCase()));
        return keys;
    }
}

@Pipe({
    name: 'groupByType',
})
export class GroupByType implements PipeTransform {
    transform(list: Array<any>, prop: string, reverse?: boolean): any {
        if (!list) {
            return null;
        }

        let grouped = {};
        for (let i = 0; i < list.length; i++) {
            if (!grouped[list[i][prop]]) {
                grouped[list[i][prop]] = [list[i]];
            } else {
                grouped[list[i][prop]].push(list[i]);
            }
        }

        if (reverse) {
            // pt pagina de contacts
            return Object.keys(grouped).map(key => ({ key, value: grouped[key] })).reverse();
        } else {
            // pt pagina de location
            return grouped;
        }
    }
}

/**
* Pipe used for uppercasing first letter of first word in a string.
* @returns {string} text with first letter uppercased
* for uppercasing first letter of every word in a string use titlecase (angular built in pipe)
*/
@Pipe({
    name: 'capitalize',
})
export class Capitalize implements PipeTransform {
    transform(text: string): string {
        return text.substr(0,1).toUpperCase() + text.substr(1);
    }
}

@Pipe({
    name: 'websitesSort'
})
export class SortPipe implements PipeTransform {
    transform(array: any, field: string): any[] {

        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return 1;
            } else if (a[field] > b[field]) {
                return -1;
            } else {
                return 0;
            }
        });
        return array;
    }
}

@Pipe({
    name: 'sortAlphabetically'
})
export class SortAlphabeticallyPipe implements PipeTransform {
    transform(array: any, field: string): any[] {

        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a,b) => a[field].localeCompare(b[field]));
        return array;
    }
}

@Pipe({
    name: 'customDevicesFilter'
})

export class CustomDevicesFilterPipe implements PipeTransform {
    constructor (
        private readonly devicesValuesService: DevicesValuesService,
        private readonly valuesService: ValuesService
    ) {}
    transform(devices: any, field: string): any[] {
        let filteredDevices = [];

        if (!devices) {
            return filteredDevices;
        }
        if (!field) {
            return devices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.protectedDevices) {
            for (const device of devices) {
                if (device.processed.protectionStatus === this.valuesService.protectionStatus.PROTECTED) {
                    filteredDevices.push(device);
                }
            }
            return filteredDevices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.atRiskDevices) {
            for (const device of devices) {
                if (device.processed.protectionStatus === this.valuesService.protectionStatus.AT_RISK) {
                    filteredDevices.push(device);
                }
            }
            return filteredDevices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.disconnectedDevices) {
            for (const device of devices) {
                if (device.processed.protectionStatus === this.valuesService.protectionStatus.DISCONNECTED) {
                    filteredDevices.push(device);
                }
            }
            return filteredDevices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.computer) {
            for (const device of devices) {
                if (this.devicesValuesService.computerDevices.has( device.device_type )) {
                    filteredDevices.push(device);
                }
            }
            return filteredDevices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.mobile) {
            for (const device of devices) {
                if (this.devicesValuesService.mobileDevices.has( device.device_type )) {
                    filteredDevices.push(device);
                }
            }
            return filteredDevices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.iot) {
            for (const device of devices) {
                if (this.devicesValuesService.iotDevices.has( device.device_type )) {
                    filteredDevices.push(device);
                }
            }
            return filteredDevices;
        }
        if (field['filter'] === this.devicesValuesService.filterNames.all) {
            return devices;
        }
        return devices;
    }
}
/**
* Method used to format number from 200000 to 200.000
* @param {number} num
* @returns formated string
*/
@Pipe({ name: 'threeDigitsFormat' })
export class ThreeDigitsFormat implements PipeTransform {
    transform(value: number): string {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
}

/**
 * Method used to apply a pipe send from another component
 * @export
 * @class WrappingPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'wrappingPipe' })
export class WrappingPipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) {}
    transform(value: any, displayPipe?: PipeTransform | 'translate', options?: any): any {
        if (value) {
            if (displayPipe === 'translate') {
                if (options) {
                    return this.translateService.instant(options.getKey(value), {[options?.useColumnAsOption]: value, ...options?.moreOptions});
                } else {
                    return this.translateService.instant(value);
                }
            } else {
                if (options) {
                    return displayPipe?.transform(value, ...Object.values(options)) ?? value;
                } else {
                    return displayPipe?.transform(value) ?? value;
                }
            }
        } else {
            return value;
        }
    }
}


/**
 * Method used to apply multiple pipes send from another component
 * @export
 * @class WrappingPipesArray
 * @implements {PipeTransform}
 */
@Pipe({ name: 'wrappingPipesArray' })
export class WrappingPipesArray implements PipeTransform {
    transform(value: any, pipesArray?: { pipe: PipeTransform, options: object}[] ): any {
        if (value) {
            for (const pipeItem of pipesArray) {
                if (pipeItem.options) {
                    value = pipeItem.pipe?.transform(value, ...Object.values(pipeItem.options)) ?? value;
                } else {
                    value = pipeItem.pipe?.transform(value) ?? value;
                }
            }
        }
        return value;
    }
}
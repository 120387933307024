import { AppLevels } from '../values/subscriptions.values.service';
import { ProductsToInstall } from '../values/values.service';

export enum InstallModalHeaders {
    SECURITY = 'Sécurité',
    PRIVACY = 'Confidentialité',
    IDENTITY_PROTECTION = 'Identity Protection',
    PARENTAL = 'Parental'
}

export type AppByLevelOutlineType = {
    [key: string]: AppByLevelType;
};

export type AppByLevelType = {
    [key in AppLevels]?: AppByLevelInfo;
};

export interface AppByLevelInfo {
    [AppByLevelProperties.INSTALL_MODAL_NAME]?: AppByLevelConcatenatedNameOptions;
    [AppByLevelProperties.NAME]: AppByLevelPlainNameOptions;
    [AppByLevelProperties.NAME_V3]?: AppByLevelPlainNameOptions;
    [AppByLevelProperties.IMAGE]?: string;
    [AppByLevelProperties.DESCRIPTION]?: string;
    [AppByLevelProperties.PRODUCT]?: ProductsToInstall;
}

export interface AppByLevelPlainNameOptions {
    [AppByLevelProperties.DEFAULT]: string;
    [key: string]: string;
}

export interface AppByLevelConcatenatedNameOptions {
    [AppByLevelProperties.DEFAULT]: AppByLevelConcatenatedName;
    [key: string]: AppByLevelConcatenatedName;
}

export interface AppByLevelConcatenatedName {
    [AppByLevelProperties.UPPER]: string;
    [AppByLevelProperties.LOWER]: Function;
}

export enum AppByLevelProperties {
    NAME = 'name',
    NAME_V3 = 'nameV3',
    INSTALL_MODAL_NAME = 'installModalName',
    DEFAULT = 'default',
    UPPER = 'upper',
    LOWER = 'lower',
    HEADER = 'header',
    IMAGE = 'image',
    DESCRIPTION = 'description',
    PRODUCT = 'product'
}

export interface App {
    app_id: string;
    app_params?: {
        level?: string;
        [key: string]: any;
    };
    [key: string]: any;
}

export enum BundleConfigurationTypes {
    INDIVIDUAL = 'individual',
    FAMILYPLAN = 'familyPlan',
    DEFAULT = 'default'
}

export type BundleConfigurationInterface = {
    [key: string]: BundleConfigurationInfo;
};

export type BundleConfigurationInfo = {
    [key in BundleConfigurationTypes]?: BundleConfigurationObject;
};

export interface BundleConfigurationObject {
    pic?: any;
    website?: any;
    seeAllFeatures?: string;
    systemRequirements?: string;
    userGuide?: any;
    name?: any;
    supportName?: string;
    plan?: string;
    desc?: string | BundleDescription;
    mainDesc?: string | BundleDescription;
}

export interface BundleDescription {
    basic: string;
    premium: string;
}

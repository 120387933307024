// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { tv4 } from "tv4";
import { map, catchError } from "rxjs/operators";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { schemas } from "../../../models/schemas";
import { ValuesService } from "../../../values/values.service";
import { ConfigService } from "src/app/common/config/config.service";
import { UsefulService } from 'src/app/common/services/global/useful/useful.service';
import { UtilsCommonService } from 'src/app/common/utils/utils-common.service';

@Injectable({
    providedIn: "root"
})
export class TasksMgmtService {
    constructor(
        private cookieService: CookieService,
        private requestService: RequestsService,
        private valuesService: ValuesService,
        private configService: ConfigService,
        private usefulService: UsefulService,
        readonly utilsCommonService: UtilsCommonService
    ) {}

    /**
     * This method is used to return the last summary report sent after executing the task for VA
     * @param {object} Contains mandatory `device_id` and `task`
     * @returns Response from server
     */
    //! am observat ca unde este folosita metoda, este comentata, momentan o las asa si sa o stergem in viitor
    // getSummaryVA(device_id, task): Observable<any> {
    //     if (!tv4.validate(device_id, schemas.schemaUuid) || task !== "va_scan") {
    //         console.error("Invalid params", tv4.error);
    //         return of("Invalid params");
    //     }
    //     let json = {
    //         id: parseInt((Math.random() * 100).toString(), 10),
    //         jsonrpc: this.valuesService.jsonrpc,
    //         method: "get_task_summary",
    //         params: {
    //             connect_source: {
    //                 user_token: this.cookieService.get(this.valuesService.cookieToken),
    //                 device_id: this.valuesService.connectDeviceId,
    //                 app_id: this.valuesService.appBOX2
    //             },
    //             connect_destination: {
    //                 device_id: device_id
    //             },
    //             task_name: task
    //         }
    //     };

    //     return this.requestService.make(
    //         this.configService.config.nimbusServer,
    //         this.valuesService.tasksMgmtService,
    //         json,
    //         "POST"
    //     );
    // }

    /**
     * This method is used to return the last summary report sent after executing the task
     * @param {object} Contains mandatory `device_id`, `info.app_id` and `info.task`
     * @returns Response from server
     */
    getSummary(device_id, task, params?): Observable<any> {
        if (
            !tv4.validate(device_id, schemas.schemaUuid) ||
            !task
        ) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_task_summary",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device_id
                },
                task_name: task
            }
        };

        if (!this.utilsCommonService.isEmptyObject(params)) {
            json.params["task_params"] = params;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.tasksMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                if (error?.error?.data?.code === 1108) {
                    return of({});
                }
                throw error;
            })
        );
    }

    /**
     * This method is used to execute a task on the device_id specified
     * @param {object} Contains mandatory `device_id`, `info.app_id`, `info.task`, `data`
     * @returns Response from server
     */
    sendTask(deviceId, info, data) {
        if (!tv4.validate(deviceId, schemas.schemaUuid) || !info || !info.task) {
            console.error("Invalid params", tv4.error);
            throw("Invalid params");
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "execute_task",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: info.app_id ? info.app_id : this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: deviceId
                },
                task_name: info.task,
                task_params: data
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.tasksMgmtService,
            json,
            "POST"
        ).pipe(
            catchError((error) => {
                if(this.usefulService.checkNested(error, "data", "code") && error.data.code === 1108) {
                    return error.data;
                }
                return throwError((error.message && error.message.internal_data) ? error.message.internal_data : error);
            }),
            map((resp) => {
                if (resp.error || resp.status === "error") {
                    throw resp.internal_data || resp;
                } else {
                    return resp.result;
                }
            }),
        );
    }

    getStatus(device_id, info) {
        if (!tv4.validate(device_id, schemas.schemaUuid) || !info || !info.task) {
            console.error("Invalid params", tv4.error);
            throw("Invalid params");
        }

        var json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_task_status",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device_id
                },
                task_name: info.task
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.tasksMgmtService,
            json,
            "POST"
        ).pipe(
            catchError((error) => {
                return throwError((error.message && error.message.internal_data) ? error.message.internal_data : error);
            }),
            map((resp) => {
                if (resp.error || resp.status === "error") {
                    throw resp.internal_data || resp;
                } else {
                    return resp.result;
                }
            })
        );
    };

    
}

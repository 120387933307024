// External
import { Injectable } from '@angular/core';

//  Internal
import { ConfigService } from '../config/config.service';
import {
    EXCEPTION_NA,
    EXCEPTION_UNKNOWN,
    EXCEPTION_WWWFUNC,
    NccAvailabilityType,
    ParentalFilterType,
    ParentalNCCAllStatusInterface,
    parentalNccUsageOrderby
} from '../models/parental-ncc/ParentalNcc.model';
import { OperatingSystems, ValuesService } from './values.service';

export enum ParentalDefaultValues {
    OTHERS = 'others',
    ALL_OTHER = 'all_other'
}

export enum Capabilities {
    ON = 'on',
    OFF = 'off',
    NA = 'n/a'
}

@Injectable({
    providedIn: 'root'
})

export class ParentalNccValuesService {

    constructor(
        private readonly configService: ConfigService,
        private readonly valuesService: ValuesService,
    ) {}

    parentalDevicesOrder = [OperatingSystems.ANDROID, OperatingSystems.IOS, OperatingSystems.WINDOWS, OperatingSystems.MAC, OperatingSystems.OSX];


    daysOfWeek = [
        "parental.key.sunday",
        "parental.key.monday",
        "parental.key.tuesday",
        "parental.key.wednesday",
        "parental.key.thursday",
        "parental.key.friday",
        "parental.key.saturday"
    ];

    daysOfWeekPlural = [
        "parental.key.sunday.plural",
        "parental.key.monday.plural",
        "parental.key.tuesday.plural",
        "parental.key.wednesday.plural",
        "parental.key.thursday.plural",
        "parental.key.friday.plural",
        "parental.key.saturday.plural"
    ];

    daysOfWeekShort = [
        "parental.key.sunday.short",
        "parental.key.monday.short",
        "parental.key.tuesday.short",
        "parental.key.wednesday.short",
        "parental.key.thursday.short",
        "parental.key.friday.short",
        "parental.key.saturday.short"
    ];

    typeTexts = {
        [ParentalFilterType.kid]: "parental.dashboard.type.kid",
        [ParentalFilterType.teen]: "parental.dashboard.type.teen",
        [ParentalFilterType.toddler]: "parental.dashboard.type.toddler"
    };


    HALF_HOUR_IN_SEC = 1800;

    minNumberOfElementsInDropdown = 4;
    minNumberOfElementsInDropdownMobile = 2;

    totalDoughnutUsageDisplayed = {
        [this.valuesService.centralPaths.parentalncc.dashboard.id]: 3,
        [this.valuesService.centralPaths.parentalncc.usage.id]: 5,
    };

    NON_IOT_PLATFORMS = new Set(["windows", "android", "osx", "ios"]);
    NON_AGENT_PLATFORMS = new Set(["android", "ios"]);

    appStatus = {
        installed: 2,
        pending: 1,
        disabled: 3,
        notInstalled: 0,
        unsupported: -1,
        notUpdated: -2
    };

    deviceStatus = {
        noIssues: 0,
        parentalDisabled: 1,
        parentalUninstaled: 2,
        parentalOffline: 3,
        locationDisabled: 4,
    };

    pendingTimeInterval = 5 * 60 * 1000; // 5 min

    extensionsFile = ['jpeg', 'png', 'jpg', 'gif'];
    maxSizeFile = 2000000;

    genderTypes: any = [
        {name: 'devices.edit.profile.modal.gender.not.specified', type: 'u'},
        {name: 'devices.edit.profile.modal.gender.male', type: 'm'},
        {name: 'devices.edit.profile.modal.gender.female', type: 'f'}
    ];

    queryParams = {
        tab: 'tab',
        period: 'period'
    };

    errorsFormCreateChild = {
        childName: {
            required: 'install.modal.child.name.error.required',
            pattern: 'install.modal.child.name.error.pattern',
            minlength: 'install.modal.child.name.error.minlength',
            maxlength: 'install.modal.child.name.error.maxlength',
            duplicate: 'install.modal.child.name.error.duplicate'
        },
        dateOfChildBirth: {
            required: 'install.modal.child.birth.error.required'
        },
        picture: {
            filesStatus: 0,
            error: '',
            extension: 'devices.profile.picture.error.type',
            size: 'devices.profile.picture.error.size',
            upload: 'devices.profile.picture.error.upload',
            remove: 'devices.profile.picture.error.remove'
        }
    };

    defaultAvatars = [
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-1.png'),
            type: "image/png",
            path: "avatar-1.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-2.png'),
            type: "image/png",
            path: "avatar-2.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-3.png'),
            type: "image/png",
            path: "avatar-3.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-4.png'),
            type: "image/png",
            path: "avatar-4.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-5.png'),
            type: "image/png",
            path: "avatar-5.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-6.png'),
            type: "image/png",
            path: "avatar-6.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-7.png'),
            type: "image/png",
            path: "avatar-7.png"
        },
        {
            relativePath: this.configService.config.pathImages.concat('parental/avatar-8.png'),
            type: "image/png",
            path: "avatar-8.png"
        }
    ];

    ageCategoryName = {
        toddler: 'toddler',
        kid: 'kid',
        teen: 'teen'
    };

    ageCategories = {
        toddler: [0,6],
        kid: [7,13],
        teen: [14,16]
    };

    MAX_AGE_TODDLER = 7;
    MAX_AGE_KID = 14;
    MAX_AGE_TEEN = 16;

    noDevicesRestrictedPages = new Set([
        this.valuesService.centralPaths.parentalncc.content.id,
        this.valuesService.centralPaths.parentalncc.dtl.id,
        this.valuesService.centralPaths.parentalncc.routines.id,
        this.valuesService.centralPaths.parentalncc.usage.id
    ]);

    iosDevicesRestrictedPages = new Set([
        this.valuesService.centralPaths.parentalncc.usage.id
    ]);

    defaultProfileSettings = {
        icon: 'bms-promo',
        text: 'install.modal.childProfile.settings.default.one'
    };

    defaultProfileBasicSettings = {
        icon: 'bms-promo',
        text: 'install.modal.childProfile.settings.default.one.basic'
    };

    ageProfileSettings = {
        [this.ageCategoryName.toddler]: {
            icon: this.defaultProfileSettings.icon,
            text: 'install.modal.childProfile.settings.toddler.one'
        },
        [this.ageCategoryName.kid]: {
            icon: this.defaultProfileSettings.icon,
            text: 'install.modal.childProfile.settings.kid.one'
        },
        [this.ageCategoryName.teen]: {
            icon: this.defaultProfileSettings.icon,
            text: 'install.modal.childProfile.settings.teen.one'
        }
    };

    ageProfileBasicSettings = {
        [this.ageCategoryName.toddler]: {
            icon: this.defaultProfileBasicSettings.icon,
            text: 'install.modal.childProfile.settings.toddler.one'
        },
        [this.ageCategoryName.kid]: {
            icon: this.defaultProfileBasicSettings.icon,
            text: 'install.modal.childProfile.settings.kid.one'
        },
        [this.ageCategoryName.teen]: {
            icon: this.defaultProfileBasicSettings.icon,
            text: 'install.modal.childProfile.settings.teen.one'
        }
    };

    encodeImagesBase64 = 'data:image/png;base64,';
    topicsImagesAlt = {
        primevideo: 'Prime Video',
        amazon: 'Amazon',
        disneyplus: 'Disney Plus',
        facebook: 'Facebook',
        instagram: 'Instagram',
        fbmessenger: 'Facebook Messenger',
        netflix: 'Netflix',
        pinterest: 'Pinterest',
        reddit: 'Reddit',
        snapchat: 'Snapchat',
        telegram: 'Telegram',
        tiktok: 'TikTok',
        twitter: 'Twitter',
        whatsapp: 'WhatsApp Messenger',
        youtube: 'YouTube',
        zoom: 'Zoom Cloud Meetings',
        ebay: 'eBay marketplace - Buy & Sell'
    };

    contentFilteringCategoriesIcons = {
        webproxy: 'webproxy',
        gambling: 'gambling',
        onlinepay: 'online-pay',
        searchengines: 'search-engine',
        religion: 'religion',
        filesharing: 'filesharing',
        videos: 'videos',
        radiomusic: 'radio',
        sports: 'sports',
        education: 'education',
        ads: 'ad-network',
        games: 'games',
        socialnetworks: 'social-network',
        news: 'news',
        onlinephotos: 'online-photos',
        webmail: 'webmail',
        jobsearch: 'job-search',
        advice: 'advice',
        government: 'government',
        tabloids: 'tabloids',
        onlinedating: 'online-dating',
        health: 'health',
        hobbies: 'hobby',
        timewasters: 'timewasters',
        travel: 'travel',
        entertainment: 'entertainment',
        financial: 'financial',
        onlineshop: 'online-shop',
        im: 'isntant-messaging',
        blogs: 'blogs',
        computersandsoftware: 'computers-and-software',
        portals: 'online',
        business: 'business',
        hosting: 'hosting',
        maturecontent: 'mature-content',
        drugs: 'drugs',
        hacking: 'hacking',
        hate: 'hate',
        illegal: 'illegal',
        narcotics: 'narcotics',
        porn: 'porn',
        suicide: 'suicide',
        weapons: 'weapons',

        // missing categories
        marketing: 'online',
        Unknown: 'online',
        adsnetwork: 'online',
        trackers: 'online',
        parked: 'online',
        misc: 'online',
        society: 'online',
        [ParentalDefaultValues.ALL_OTHER]: 'miscellaneous'
    };

    contentFilteringCategoriesNames = {
        webproxy : 'parental.contentfiltering.categories.names.web_proxy',
        gambling: 'parental.contentfiltering.categories.names.gambling',
        onlinepay: 'parental.contentfiltering.categories.names.online_payments',
        searchengines: 'parental.contentfiltering.categories.names.search_engines',
        religion: 'parental.contentfiltering.categories.names.religious',
        filesharing: 'parental.contentfiltering.categories.names.file_sharing',
        videos: 'parental.contentfiltering.categories.names.videos',
        radiomusic: 'parental.contentfiltering.categories.names.radio_music',
        sports: 'parental.contentfiltering.categories.names.sports',
        education: 'parental.contentfiltering.categories.names.education',
        ads: 'parental.contentfiltering.categories.names.ads',
        games: 'parental.contentfiltering.categories.names.games',
        socialnetworks: 'parental.contentfiltering.categories.names.social_networking',
        news: 'parental.contentfiltering.categories.names.news',
        onlinephotos: 'parental.contentfiltering.categories.names.photos_online',
        webmail: 'parental.contentfiltering.categories.names.web_mail',
        jobsearch: 'parental.contentfiltering.categories.names.job_search',
        advice: 'parental.contentfiltering.categories.names.advice',
        government: 'parental.contentfiltering.categories.names.government',
        tabloids: 'parental.contentfiltering.categories.names.tabloids',
        onlinedating: 'parental.contentfiltering.categories.names.online_dating',
        health: 'parental.contentfiltering.categories.names.health',
        hobbies: 'parental.contentfiltering.categories.names.hobbies',
        timewasters: 'parental.contentfiltering.categories.names.time_wasters',
        travel: 'parental.contentfiltering.categories.names.travel',
        entertainment: 'parental.contentfiltering.categories.names.entertainment',
        financial: 'parental.contentfiltering.categories.names.financial',
        onlineshop: 'parental.contentfiltering.categories.names.online_shopping',
        im: 'parental.contentfiltering.categories.names.online_messaging',
        blogs: 'parental.contentfiltering.categories.names.blogs',
        computersandsoftware: 'parental.contentfiltering.categories.names.computersandsoftware',
        portals: 'parental.contentfiltering.categories.names.portals',
        business: 'parental.contentfiltering.categories.names.business',
        hosting: 'parental.contentfiltering.categories.names.hosting',
        maturecontent: 'parental.contentfiltering.categories.names.mature_content',
        drugs: 'parental.contentfiltering.categories.names.drugs',
        hacking: 'parental.contentfiltering.categories.names.hacking',
        hate: 'parental.contentfiltering.categories.names.hate',
        illegal: 'parental.contentfiltering.categories.names.illegal',
        narcotics: 'parental.contentfiltering.categories.names.narcotics',
        porn: 'parental.contentfiltering.categories.names.porn',
        suicide: 'parental.contentfiltering.categories.names.suicide',
        weapons: 'parental.contentfiltering.categories.names.weapons',
        na: 'parental.contentfiltering.categories.names.na',
        //to be updated / deleted for PR, only used in this state for Demo
        marketing: 'parental.contentfiltering.categories.names.marketing',
        Unknown: 'parental.contentfiltering.categories.names.unknown2',
        adsnetwork: 'parental.contentfiltering.categories.names.adsnetwork',
        trackers: 'parental.contentfiltering.categories.names.trackers',
        parked: 'parental.contentfiltering.categories.names.parked',
        misc: 'parental.contentfiltering.categories.names.misc',
        society: 'parental.contentfiltering.categories.names.society',

        [ParentalDefaultValues.OTHERS]: 'parental.dashboard.categories.and.topics.others',
        [ParentalDefaultValues.ALL_OTHER]: 'parental.dashboard.categories.and.topics.all.other'
    };

    contentFiltering = {
        status : {
            all: {
                value: 'all',
                text: 'parental.contentfiltering.allItems'
            },
            allowed: {
                value: 'allowed',
                text: 'parental.contentfiltering.allowed'
            },
            blocked: {
                value: 'blocked',
                text: 'parental.contentfiltering.blocked'
            }
        },
        lastExceptionUpdate: 'lastExceptionUpdate',
        lastCategoryUpdate: 'lastCategoryUpdate'
    };

    contentUsageOrderBy = {
        [parentalNccUsageOrderby.timeDesc]: {
            text: 'parental.dashboard.categories.and.topics.list.sort.time.desc',
            orderBy : {
                propertyName: 'value',
                direction: 'desc',
            }
        },
        [parentalNccUsageOrderby.timeAsc]: {
            text: 'parental.dashboard.categories.and.topics.list.sort.time.asc',
            orderBy : {
                propertyName: 'value',
                direction: 'asc'
            }
        },
        [parentalNccUsageOrderby.alphabeticallyDesc]: {
            text: 'parental.dashboard.categories.and.topics.list.sort.aplhabetically.desc',
            orderBy : {
                propertyName: 'label',
                direction: 'desc',
            }
        },
        [parentalNccUsageOrderby.aplhabeticallyAsc] : {
            text: 'parental.dashboard.categories.and.topics.list.sort.aplhabetically.asc',
            orderBy : {
                propertyName: 'label',
                direction: 'asc'
            }
        }
    };

    contentFilteringFilterStatus = {
        all: "All items",
        allowed: "Allowed",
        blocked: "Blocked"
    };

    contentFilteringRestrictionsStatus = {
        allowed : 'parental.contentfiltering.allowed',
        blocked : 'parental.contentfiltering.blocked'
    };

    contentFilteringRestrictionStatus = {
        allowed : 'Allowed',
        blocked : 'Blocked'
    };

    contentFilteringTabs = {
        categories: "categories",
        exceptions : "exceptions",
        appsAndWebsites : "appsAndWebsites",
        customWebsites : "customWebsites"
    };

    routineTypes = {
        focusTime: 'focustime',
        dinnerTime: 'dinnertime',
        bedTime: 'bedtime'
    };

    routineTitles = {
        [this.routineTypes.focusTime]: 'parental.routines.title.focustime',
        [this.routineTypes.bedTime]: 'parental.routines.title.bedtime',
        [this.routineTypes.dinnerTime]: 'parental.routines.title.dinnertime'
    };

    routineDescriptions = {
        [this.routineTypes.focusTime]:  'parental.routines.description.focustime',
        [this.routineTypes.bedTime]: 'parental.routines.description.bedtime',
        [this.routineTypes.dinnerTime]: 'parental.routines.description.dinnertime',
    };

    routineActions = {
        [this.routineTypes.focusTime]: {
            first: 'parental.routines.first.action.focustime',
            second: 'parental.routines.second.action.focustime',
            alert: 'parental.routines.alert.text.focustime'
        },
        [this.routineTypes.bedTime]: {
            first: 'parental.routines.first.action.bedtime',
            second: 'parental.routines.second.action.bedtime',
            alert: 'parental.routines.alert.text.bedtime'
        },
    };

    routineTips = {
        [this.routineTypes.focusTime]:  'parental.routines.tip.card.text.focustime',
        [this.routineTypes.bedTime]: 'parental.routines.tip.card.text.bedtime',
        [this.routineTypes.dinnerTime]: 'parental.routines.tip.card.text.dinnertime',
    };

    routineBtnEnable = {
        [this.routineTypes.focusTime]:  'parental.routines.button.enable.focustime',
        [this.routineTypes.bedTime]: 'parental.routines.button.enable.bedtime',
        [this.routineTypes.dinnerTime]: 'parental.routines.button.enable.dinnertime',
    };

    routineBackToContent = {
        [this.routineTypes.focusTime]: 'parental.back.to.content.focustime',
        [this.routineTypes.bedTime]: 'parental.back.to.content.bedtime',
        [this.routineTypes.dinnerTime]: 'parental.back.to.content.dinnertime'
    };

    public readonly installParentalActions = [
        {
            title: 'parental.dashboard.profile.set.pin.title',
            description: 'parental.dashboard.profile.set.pin.description',
            iconType: 'user-data',
            icon: 'pin'
        },
        {
            title: 'parental.dashboard.profile.create.profile.title',
            description: 'parental.dashboard.profile.create.profile.description',
            iconType: 'action',
            icon: 'add-account'
        },
        {
            title: 'parental.dashboard.profile.install.app.title',
            description: 'parental.dashboard.profile.install.app.description',
            iconType: 'general',
            icon: 'download'
        }
    ];

    routinesIcons = {
        focustime: {
            type: 'web',
            icon: 'education'
        },
        dinnertime: {
            type: 'feature',
            icon: 'food'
        },
        bedtime: {
            type: 'action',
            icon: 'theme'
        },
    };

    dashboardRoutes = {
        contentfiltering: {
            name: 'parental.contentfiltering.title',
            path: this.valuesService.centralPaths.parentalncc.content.path
        },
        dailytimelimit: {
            name: 'parental.screentime.dtl.title',
            path: this.valuesService.centralPaths.parentalncc.dtl.path
        },
        focustime: {
            name: 'parental.routines.title.focustime',
            path: this.valuesService.centralPaths.parentalncc.routines.focustime.path
        },
        bedtime: {
            name: 'parental.routines.title.bedtime',
            path: this.valuesService.centralPaths.parentalncc.routines.bedtime.path
        },
        dinnertime: {
            name: 'parental.routines.title.dinnertime',
            path: this.valuesService.centralPaths.parentalncc.routines.dinnertime.path
        },
        usage: {
            name: 'parental.dashboard.categories.and.topics.usage.total.screen.time',
            path: this.valuesService.centralPaths.parentalncc.usage.path
        },
        blockedcontent: {
            name: 'parental.dashboard.categories.and.topics.usage.total.screen.time',
            path: this.valuesService.centralPaths.parentalncc.blockedcontent.path
        }
    };

    maxAccountAge = 85;
    editProfileBirthdayYearsRange = 16;
    milisecondsInSeconds = 1000;
    daysInAMonth = 31;
    hoursInDay = 24;
    minutesInHour = 60;
    secondsInMinute = 60;
    daysInWeek = 7;
    last30Days = 30;
    dtlDefaultHours = 1.5;
    milisecondsInMinutes = this.milisecondsInSeconds * this.secondsInMinute;
    milisecondsInHour = this.milisecondsInMinutes * this.minutesInHour;
    secondsInDay = this.hoursInDay * this.minutesInHour * this.secondsInMinute;
    routineDefaultTime = {
        start: 7*this.minutesInHour*this.secondsInMinute,
        end: 21*this.minutesInHour*this.secondsInMinute,
    };

    dtlDefaultSeconds = this.dtlDefaultHours*this.minutesInHour*this.secondsInMinute;

    daysInFebruary = {
        bisect: 29,
        nonBisect: 28
    };

    parentalDashboardValues = {
        maximumUsedCategories : 3,
        maximumUsedTopics : 3,
        notApplicable : 'N/a'
    };

    categoriesAndTopicsUsageValues = {
        maximumUsedCategories : 5,
        maximumUsedTopics : 5
    };

    screenTimeChartPeriods = {
        today: 'today',
        yesterday: 'yesterday',
        last7Days: 'last7Days',
        last30Days: 'last30Days'
    };

    usagePeriods = {
        last7Days: 'last7Days',
        last30Days: 'last30Days'
    };

    xyChartColors = {
        label: "#6A6C72",
        columns: "#005EFF"
    };

    gaugeChartColors = {
        label1: '#6B3DB6',
        label2: '#6A7281',
        white: '#FFFFFF',
        gradient1Color1: '#C33CD9',
        gradient1Color2: '#337FFE',
        gradient2Color1: '#EA0220',
        gradient2Color2: '#F46A00',
        background: '#F0F7FA'
    };

    // TODO after master-development-ncc is updated with master-dev chartStyle needs to be moved to a chart.values.seervice
    // this chartStyle must be the same as dashboard.values.service's chartStyle
    chartStyle = {
        bar: {
            backgroundColor: '#E2001A',
            hoverBackgroundColor: '#FC6F7F'
        },
        ticks: {
            padding: 16,
            maxRotation: 0,
            autoSkipPadding: 8
        },
        gridLines: {
            drawTicks: true,
            drawBorder: false,
            tickMarkLength: 0,
            color: '#EDEDF0',
            borderDash: [3],
            zeroLineWidth: 0,
            zeroLineBorderDash: [3],
            zeroLineColor: '#EDEDF0',
        },
        tooltip: {
            backgroundColor: '#FC6F7F',
            borderWidth: 1,
            borderColor: '#E2001A',
            titleFontStyle: 'bold',
            titleFontColor: 'black',
            titleSpacing: 1,
            titleMarginBottom: 0,
            footerFontColor: '#6A7281',
            footerFontStyle: 'normal',
            footerMarginTop: 10,
            xPadding: 12,
            yPadding: 12,
            displayColors: false,
            caretPadding: 5
        },
    };

    hoursToMiliSecondsValues = {
        entireDay : 86400000
    };

    routinesMobileDaysTexts = [
        'parental.routines.set.mobile.sun.',
        'parental.routines.set.mobile.mon.',
        'parental.routines.set.mobile.tue.',
        'parental.routines.set.mobile.wed.',
        'parental.routines.set.mobile.thu.',
        'parental.routines.set.mobile.fri.',
        'parental.routines.set.mobile.sat.'
    ];

    months = [
        "parental.screentime.month.1",
        "parental.screentime.month.2",
        "parental.screentime.month.3",
        "parental.screentime.month.4",
        "parental.screentime.month.5",
        "parental.screentime.month.6",
        "parental.screentime.month.7",
        "parental.screentime.month.8",
        "parental.screentime.month.9",
        "parental.screentime.month.10",
        "parental.screentime.month.11",
        "parental.screentime.month.12"
    ];

    monthsShort = [
        "parental.screentime.month.short.1",
        "parental.screentime.month.short.2",
        "parental.screentime.month.short.3",
        "parental.screentime.month.short.4",
        "parental.screentime.month.short.5",
        "parental.screentime.month.short.6",
        "parental.screentime.month.short.7",
        "parental.screentime.month.short.8",
        "parental.screentime.month.short.9",
        "parental.screentime.month.short.10",
        "parental.screentime.month.short.11",
        "parental.screentime.month.short.12"
    ];

    usageColors = {
        amethist: {
            rgb: '#8F5EE0',
            bgClass: 'a2',
            colorClass: 'a2-10'
        },
        jade: {
            rgb: '#02A698',
            bgClass: 'a4',
            colorClass: 'a4-10'
        },
        emerald_10: {
            rgb: '#A3E1BF',
            bgClass: 'success-10',
            colorClass: 'success'
        },
        apricot: {
            rgb: '#F46A00',
            bgClass: 'warning',
            colorClass: 'warning-10'
        },
        apricot_15: {
            rgb: '#FAAF77',
            bgClass: 'warning-15',
            colorClass: 'warning'
        },
        azure_10: {
            rgb: '#9AD4F4',
            bgClass: 'a3-10',
            colorClass: 'a3'
        },
        lime: {
            rgb: '#44AA44',
            bgClass: 'a6',
            colorClass: 'a6-10'
        },
        peach: {
            rgb: '#F55B56',
            bgClass: 'a7',
            colorClass: 'a7-10'
        },
        mulberry: {
            rgb: '#B84A9F',
            bgClass: 'a1',
            colorClass: 'a1-10'
        },
        obsidian_10: {
            rgb: '#EDEDF0',
            bgClass: 'shade-10',
            colorClass: 'shade-60'
        }
    };

    categoriesUsageColors = [
        this.usageColors.amethist,
        this.usageColors.jade,
        this.usageColors.emerald_10,
        this.usageColors.apricot,
        this.usageColors.apricot_15
    ];

    topicsUsageColors = [
        this.usageColors.lime,
        this.usageColors.azure_10,
        this.usageColors.peach,
        this.usageColors.mulberry,
        this.usageColors.apricot_15
    ];

    public topicsExceptions = new Set([
        EXCEPTION_NA.id,
        EXCEPTION_UNKNOWN.id,
        EXCEPTION_WWWFUNC.id
    ]);

    otherUsageColor = this.usageColors.obsidian_10;

    parentalDashboardDevicesIcons = {
        phone: 'phone',
        computer: 'laptop',
        laptop: 'laptop'
    };

    childrenDevicesLimit = 7;

    recommendedDTLinSec = 5400;

    recommendedBedTimeInSec: [number, number] = [75600, 25200];

    productUninstalled = "product_unistalled";

    maxWebsitesExceptions = 5;

    zeroTimeSpent = 0;

    contentFilteringStatusInfo = 15;

    lowPercentage = 1;

    blockedContentPageLimit = 30; //possible values: between 5 and 50 and as a multiple of 5

    wrapTextLength = 25;

    public readonly minIOSVersion = 15;
    public readonly minMacOSVersion = 11;

    public readonly updateParentalNccStatus: ParentalNCCAllStatusInterface = {
        [OperatingSystems.ANDROID]: {
            [NccAvailabilityType.AVAILABLE]: {
                iconType: 'feedback',
                icon: 'circle-attention',
                status: 'parental.status.update.app.and.allow.permissions',
                tooltip: 'parental.status.update.android.description'
            }
        },
        [OperatingSystems.IOS]: {
            [NccAvailabilityType.AVAILABLE]: {
                iconType: 'feedback',
                icon: 'circle-attention',
                status: 'parental.status.update.app.and.allow.permissions',
                tooltip: 'parental.status.update.ios.description'
            },
            [NccAvailabilityType.UNAVAILABLE]: {
                iconType: 'feedback',
                icon: 'circle-x',
                status: 'parental.status.unavailable',
                tooltip: 'parental.status.update.ios.unavailable.description'
            }
        },
        [OperatingSystems.WINDOWS]: {
            [NccAvailabilityType.AVAILABLE]: {
                iconType: 'feedback',
                icon: 'circle-attention',
                status: 'parental.status.update.app',
                tooltip: 'parental.status.update.windows.description'
            }
        },
        [OperatingSystems.OSX]: {
            [NccAvailabilityType.AVAILABLE]: {
                iconType: 'feedback',
                icon: 'circle-attention',
                status: 'parental.status.update.app.and.allow.permissions',
                tooltip: 'parental.status.update.macos.description'
            },
            [NccAvailabilityType.UNAVAILABLE]: {
                iconType: 'feedback',
                icon: 'circle-x',
                status: 'parental.status.unavailable',
                tooltip: 'parental.status.update.macos.unavailable.description'
            }
        }
    }
}

import { Injectable } from '@angular/core';
import { ValuesService } from '../../values/values.service';
import { UrlProperties } from '../guards/models/UrlProperties.model';
import { Router, UrlTree } from '@angular/router';
import { UsefulService } from '../global/useful/useful.service';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {

    constructor(
        private readonly valuesService: ValuesService,
        private readonly router: Router,
        private readonly usefulService: UsefulService
    ) {}

    appQueryParams = {};
    checkedPersistantQueryParams = false;
    tempUrlQueryParams = new Set([
        this.valuesService.queryParams.adobeMc,
        this.valuesService.queryParams.url,
        this.valuesService.queryParams.dType,
        this.valuesService.queryParams.format
    ]);

    /**
     * Saves all query params from public URL into buffer or storage or both
     * @param {UrlProperties} queryObject Object that contains all information about query params
     */
    public saveQueryParamsPersistently(queryObject: any): void {
        for (const key in queryObject) {
            if (this.valuesService.persistentQueryParams.has(key)) {
                this.set(key, queryObject[key], true, this.valuesService.persistentQueryParamsTimes[key]);
            } else {
                this.set(key, queryObject[key]);
            }
        }
    }

    /**
     * Saves all query params from URL into buffer or storage or both
     * @param {UrlProperties} queryObject Object that contains all information about query params
     */
    public saveQueryParamsNoPersistance(queryObject: any): void {
        for (const key in queryObject) {
            this.set(key, queryObject[key]);
        }
    }

    /**
     * Function that creates an URL Tree without query params
     * @param {UrlProperties} urlProperties Object that contains all information about URL
     * @returns {Observable<boolean|UrlTree>} True when no query  params should be cleaned / URL Tree with no query params
     */
    public getCleanedRouteOfCleanQueryParams(urlProperties: UrlProperties): boolean|UrlTree {
        if (!urlProperties.queryObject || Object.keys(urlProperties.queryObject).length !== 0) {
            /**
             * daca exista paramaetri speciali ii lasam in link cat este nevoie
             * verificam daca au fost verificati navigarea asta
             */
            if (!this.isCheckedPersistantQueryParams()) {
                const _persistantQueryParams = this.retrievePersistantQueryParams(urlProperties.queryObject);
                if (Object.keys(_persistantQueryParams).length !== 0) {
                    const queryParams = this.usefulService.jsonToQueryString(_persistantQueryParams);
                    const urlPath = urlProperties.entirePath.concat(urlProperties.outletsString).concat('?', queryParams);
                    return this.router.parseUrl(urlPath);
                }
                return this.router.parseUrl(urlProperties.entirePath.concat(urlProperties.outletsString));
            }
            return true;
        }
        return true;
    }

    /**
     * Checks validity of all params with timestamps. If expired they will be deleted from sstorage.
     * @param {object} params Available params with values
     * @param {object} timestamps Available params with timestamps
     * @returns { params: {object}, timestamps: {object} } Returns valid params and timestamps
     */
    private _checkParamTimestamps(params, timestamps) {
        const auxParams = {};
        const auxTimestamps = {};
        for (const param in params) {
            if (timestamps[param] > new Date().getTime()) {
                auxParams[param] = params[param];
                auxTimestamps[param] = timestamps[param];
            }
        }

        sessionStorage.setItem(this.valuesService.sessionStorageAllQueryParams, JSON.stringify(auxParams));
        sessionStorage.setItem(this.valuesService.sessionStorageAllQueryParamsTimestamps, JSON.stringify(auxTimestamps));

        return {
            params: auxParams,
            timestamps: auxTimestamps
        };
    }

    /**
     * Function that checks if the special query params have been consumed
     * @return {boolean} If special query params have been consumed
     */
    public isCheckedPersistantQueryParams() {
        return this.checkedPersistantQueryParams;
    }

    /**
     * Function that retrieves all the special query params that must be kept a litlle in the URL
     * @param {*} queryParams
     * @return {Object} Object with query params that must be preserved one time only in URL
     * @memberof QueryParamsService
     */
    public retrievePersistantQueryParams(queryParams) {
        const auxParams = {};
        for (const param in queryParams) {
            if (this.tempUrlQueryParams.has(param)) {
                auxParams[param] = queryParams[param];
            }
        }
        this.checkedPersistantQueryParams = true;
        return auxParams;
    }

    /**
     * Sets a query param to buffer and/or sessionStorage for later use.
     * @param {string} param Param key
     * @param {string} value Param Value
     * @param {boolean} [persistent] Save to storage
     * @param {EpochTimeStamp} [expiry] Expiry of storage save
     */
    public set(param, value, persistent?, expiry?) {
        if (!expiry) {
            expiry = this.valuesService.persistentQueryParamsMaxTime;
        }
        this.appQueryParams[param] = value;

        if (persistent) {
            let _persistentQueryParams = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParams);
            let _persistentQueryParamsTimes = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParamsTimestamps);

            if (_persistentQueryParams) {
                let _persistentQueryParamsObject = JSON.parse(_persistentQueryParams);
                let _persistentQueryParamsTimesObject = JSON.parse(_persistentQueryParamsTimes);
                _persistentQueryParamsObject[param] = value;
                _persistentQueryParamsTimesObject[param] = new Date().getTime() + expiry;
                _persistentQueryParams = JSON.stringify(_persistentQueryParamsObject);
                _persistentQueryParamsTimes = JSON.stringify(_persistentQueryParamsTimesObject);
            } else {
                let _persistentQueryParamsObject = {};
                let _persistentQueryParamsTimesObject = {};
                _persistentQueryParamsObject[param] = value;
                _persistentQueryParamsTimesObject[param] = new Date().getTime() + expiry;
                _persistentQueryParams = JSON.stringify(_persistentQueryParamsObject);
                _persistentQueryParamsTimes = JSON.stringify(_persistentQueryParamsTimesObject);
            }
            sessionStorage.setItem(this.valuesService.sessionStorageAllQueryParams, _persistentQueryParams);
            sessionStorage.setItem(this.valuesService.sessionStorageAllQueryParamsTimestamps, _persistentQueryParamsTimes);
        }
    }

    /**
     * Returns value of a saved query param checking in order: storage / buffer
     * @param {string} paramName
     * @returns Value of param / null if nothing found
     */
    public get(paramName) {
        let _persistentQueryParams = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParams);
        let _persistentQueryParamsTimes = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParamsTimestamps);
        let _persistentQueryParamsObject = {};
        let _persistentQueryParamsTimesObject = {};

        if (_persistentQueryParams) {
            _persistentQueryParamsObject = JSON.parse(_persistentQueryParams);
            _persistentQueryParamsTimesObject = JSON.parse(_persistentQueryParamsTimes);
        }

        if (_persistentQueryParamsObject.hasOwnProperty(paramName) && (_persistentQueryParamsTimesObject[paramName] < new Date().getTime())) {
            delete _persistentQueryParamsObject[paramName];
            delete _persistentQueryParamsTimesObject[paramName];
            _persistentQueryParams = JSON.stringify(_persistentQueryParamsObject);
            _persistentQueryParamsTimes = JSON.stringify(_persistentQueryParamsTimesObject);
            sessionStorage.setItem(this.valuesService.sessionStorageAllQueryParams, _persistentQueryParams);
            sessionStorage.setItem(this.valuesService.sessionStorageAllQueryParamsTimestamps, _persistentQueryParamsTimes);
        }

        if (_persistentQueryParamsObject.hasOwnProperty(paramName)) {
            return _persistentQueryParamsObject[paramName];
        } else if (this.appQueryParams.hasOwnProperty(paramName)) {
            return this.appQueryParams[paramName];
        } else {
            return null;
        }
    }

    /**
     * Returns all available params saved in buffer and storage
     * @returns {object} All params with values availables ( buffer and storage )
     */
    public getAll() {
        let _persistentQueryParams = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParams);
        let _persistentQueryParamsTimes = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParamsTimestamps);
        let _persistentQueryParamsObject = {};
        let _persistentQueryParamsTimesObject = {};

        if (_persistentQueryParams) {
            _persistentQueryParamsObject = JSON.parse(_persistentQueryParams);
            _persistentQueryParamsTimesObject = JSON.parse(_persistentQueryParamsTimes);
            let _validObjects = this._checkParamTimestamps(_persistentQueryParamsObject, _persistentQueryParamsTimesObject);
            _persistentQueryParamsObject = _validObjects.params;
            _persistentQueryParamsTimesObject = _validObjects.timestamps;

            for (let param in _persistentQueryParamsObject) {
                this.appQueryParams[param] = _persistentQueryParamsObject[param];
            }
        }
        return this.appQueryParams;
    }

    /**
     * Function that removes specific query params from buffer and storage
     * @param {Array[string]} params Array of properties to be removed from appQueryParams (storage and buffer)
     */
    public remove(params) {
        const _persistentQueryParams = sessionStorage.getItem(this.valuesService.sessionStorageAllQueryParams);
        let _persistentQueryParamsObject = {};

        if (_persistentQueryParams) {
            _persistentQueryParamsObject = JSON.parse(_persistentQueryParams);
        }

        for (let key of params) {
            delete this.appQueryParams[key];
            delete _persistentQueryParamsObject[key];
        }
    }

    /**
     * Function that removes all query params from buffer and storage
     */
    public removeAll() {
        this.appQueryParams = {};
        sessionStorage.removeItem(this.valuesService.sessionStorageAllQueryParams);
        sessionStorage.removeItem(this.valuesService.sessionStorageAllQueryParamsTimestamps);
    }

}
